import { FC, PropsWithChildren, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MapEvent, MapProps } from '@vis.gl/react-google-maps';
import { useDebouncedCallback } from 'use-debounce';

import { MapLayerApi } from '@/api';
import fireHydrantImage from '@/assets/images/pngs/fire_hydrant.png';
import { MapBreadcrumbItem } from '@/components/GoogleMap/MapBreadcrumb';
import { useToastStore } from '@/store';

import { GoogleMap, MapBounds } from './GoogleMap';
import { IMarker } from './MapMarker';

interface GoogleMapProps extends Omit<MapProps, 'center'>, PropsWithChildren {
  center?: google.maps.LatLngLiteral;
  markers?: IMarker[];
  breadcrumb?: MapBreadcrumbItem[];
  onHandleMapTypeIdChange?: (mapTypeId?: string) => void;
  showContextMenu?: boolean;
  showStreetViewControl?: boolean;
  onCloseMarkerInfo?: (key: string) => void;
  showZoomControl?: boolean;
}

export const FireHydrantMap: FC<GoogleMapProps> = (props) => {
  const {
    markers = [],
    breadcrumb,
    onHandleMapTypeIdChange,
    showContextMenu,
    showStreetViewControl,
    onCloseMarkerInfo,
    showZoomControl = true,
    ...rest
  } = props;
  const [queryParams] = useSearchParams();
  const [hydrantPointMarkers, setHydrantPointMarkers] = useState<IMarker[]>([]);
  const { updateToast } = useToastStore();

  const mapTypeId = queryParams.get('mapTypeId') || 'roadmap';

  const fetchFireHydrantPoints = async (bounds: MapBounds) => {
    try {
      const params = {
        page: 1,
        limit: 1000,
        filter: JSON.stringify({
          $and: [
            { type: 'FIRE_HYDRANT' },
            { 'point.coordinates.0': { $gte: bounds.west, $lte: bounds.east } },
            {
              'point.coordinates.1': { $gte: bounds.south, $lte: bounds.north },
            },
          ],
        }),
      };

      const res = await MapLayerApi.getPointLayers(params);
      const hydrantMarkers = res.data.results.map(({ _id, name, point }) => ({
        key: String(_id),
        position: { lng: point.coordinates[0], lat: point.coordinates[1] },
        icon: fireHydrantImage,
        info: name,
      }));
      setHydrantPointMarkers(hydrantMarkers);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleMapTypeIdChange = (event: MapEvent) => {
    if (onHandleMapTypeIdChange) {
      onHandleMapTypeIdChange(event.map.getMapTypeId());
    }
  };

  const handleBoundMapChange = useDebouncedCallback(
    (bounds: MapBounds, zoom: number) => {
      if (zoom > 15) {
        fetchFireHydrantPoints(bounds);
      } else {
        setHydrantPointMarkers([]);
      }
    },
    500,
  );

  return (
    <GoogleMap
      markers={hydrantPointMarkers.concat(markers)}
      onMapTypeIdChanged={handleMapTypeIdChange}
      mapTypeId={mapTypeId}
      breadcrumb={breadcrumb}
      showContextMenu={showContextMenu}
      showStreetViewControl={showStreetViewControl}
      handleBoundMapChange={handleBoundMapChange}
      onCloseMarkerInfo={onCloseMarkerInfo}
      showZoomControl={showZoomControl}
      {...rest}
    />
  );
};
