import { useMemo } from 'react';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { TableColumn, TableContainer } from '@/components';
import { IMasterName } from '@/models';
import { getAddressLocation } from '@/services';
import { flattenObject, formatPhoneNumber } from '@/utils';

interface MasterVehicleOwnerTableProps {
  columns: TableColumn[];
  owners: IMasterName[];
  registeredOwnerId?: string;
  onSelectOwner?: (selectedOwner?: IMasterName) => void;
}

export const MasterVehicleOwnerTable = ({
  columns,
  owners,
  registeredOwnerId,
  onSelectOwner,
}: MasterVehicleOwnerTableProps) => {
  const rows = useMemo(() => {
    return owners.map((owner) => flattenObject(owner));
  }, [owners]);

  const handleSelectOwner = (id: string) => {
    if (onSelectOwner) {
      const selectedOwner = owners.find(({ _id }) => _id === id);
      onSelectOwner(selectedOwner);
    }
  };

  const renderColumn = (fieldKey: string, row: Record<string, any>) => {
    switch (fieldKey) {
      case 'action': {
        return (
          <Button
            color="error"
            onClick={() => handleSelectOwner(row._id || '')}
          >
            {row._id === registeredOwnerId ? 'Remove' : 'Use information'}
          </Button>
        );
      }
      case 'phoneNumber':
        return row.phoneNumbers?.length
          ? formatPhoneNumber(row.phoneNumbers[0].phoneNumber)
          : '';
      case 'address':
        return getAddressLocation(row.address);
      default:
        return row[fieldKey as keyof typeof row] as string;
    }
  };

  return (
    <TableContainer sx={{ padding: 0, minHeight: 130 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((field) => (
              <TableCell key={field.key}>{field.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row._id}>
              {columns.map((field) => (
                <TableCell key={field.key} style={{ width: 100 }}>
                  {renderColumn(field.key, row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
