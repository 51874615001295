import { PatternFormat } from 'react-number-format';

import { Stack, styled } from '@mui/material';

import { IcPhone } from '@/assets/images';
import colors from '@/theme/variables/colors';
import { parsePhoneNumber } from '@/utils';

export const CFSCallerItemRoot = styled(Stack)(() => ({
  flexDirection: 'row',
  '& .ic-phone': {
    '& path': {
      stroke: colors.grey[80],
    },
  },
}));

interface CFSCallerItemProps {
  phoneNumber?: string;
}

export const CFSCallerItem = ({ phoneNumber }: CFSCallerItemProps) => {
  return (
    <CFSCallerItemRoot>
      <IcPhone className="ic-phone" />
      <PatternFormat
        value={parsePhoneNumber(phoneNumber)}
        format="(###) ###-####"
        style={{
          marginLeft: 5,
          width: 130,
          backgroundColor: 'transparent',
          border: '0px',
          color: colors.grey[80],
          fontFamily: 'Roboto, sans-serif',
          fontSize: '0.875rem',
        }}
      />
    </CFSCallerItemRoot>
  );
};
