import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Grid, styled } from '@mui/material';
import { MapMouseEvent } from '@vis.gl/react-google-maps';

import { AddressApi, CfsApi } from '@/api';
import { IcArrowLeft, IcArrowRight, IcMarkerCfs } from '@/assets/images';
import { Page, UnitShiftList, UnitShiftListModal } from '@/components';
import { ExpandControl, FireHydrantMap, IMarker } from '@/components/GoogleMap';
import { ResetMapControl } from '@/components/GoogleMap/ResetMapControl';
import { SearchFilterControl } from '@/components/GoogleMap/SearchFilterControl';
import { defaultMapCenter } from '@/config';
import { LocalStorageItems } from '@/constants';
import { CFSProvider, UnitShiftProvider } from '@/contexts';
import { useCFSContext, useUnitShiftContext } from '@/hooks';
import { IUnit } from '@/models';
import { googlePlaceService } from '@/services';
import { useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';

interface UnitFormProps {
  unit?: IUnit;
}

export const NEW_CFS_MARKER_ID = 'new-cfs-id';

const HideButton = styled(Button)`
  writing-mode: tb-rl;
  transform: rotate(180deg);
  height: 100%;
  font-size: 15px;
  min-width: 20px;
  text-align: top;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: baseline;
  padding: 12px;
  border-radius: 0 8px 8px 0;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

const UnitShifts: FC<UnitFormProps> = () => {
  const { updateToast } = useToastStore();

  const loadedUnitShiftModalOpen =
    JSON.parse(
      localStorage.getItem(LocalStorageItems.UnitShiftListOpen) ?? 'true',
    ) === true;

  const [isUnitShiftModalOpen, setIsUnitShiftModalOpen] = useState(
    loadedUnitShiftModalOpen,
  );
  const [cfsModalOpen, setCfsModalOpen] = useState(false);
  const [createdCfsId, setCreatedCfsId] = useState('');
  const [currentExpandedPlace, setCurrentExpandedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useUserStore();
  const {
    fetchAssignedUnitShifts,
    activeCfsMarkers,
    filteredUnitShiftMarkers,
    filteredCfsMarkers,
  } = useUnitShiftContext();

  const { cfsMapMarkers = [] } = useCFSContext();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>();
  const [resetTrigger, setResetTrigger] = useState(0);

  const cfsId = searchParams.get('cfsId') || undefined;
  const cfsMarkers =
    activeCfsMarkers.filter((marker) => !cfsId || marker.key === cfsId) || [];
  const accountCoordinates = account?.settings.center?.coordinates;
  const cfsPosition = cfsMarkers[0]?.position;

  useEffect(() => {
    if (cfsPosition && cfsId) {
      setMapCenter(cfsPosition);
    } else {
      if (accountCoordinates) {
        setMapCenter({
          lng: accountCoordinates[0],
          lat: accountCoordinates[1],
        });
      } else {
        setMapCenter(defaultMapCenter);
      }
    }
  }, [cfsId, JSON.stringify(cfsPosition)]);

  const handleExpandMap = async () => {
    window.close();
  };

  useEffect(() => {
    fetchAssignedUnitShifts();
  }, []);

  const handleMapTypeIdChange = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('mapTypeId');
    setSearchParams(newSearchParams);
  };

  const handleCfsCreate = async () => {
    if (currentExpandedPlace && currentExpandedPlace.place_id) {
      try {
        const address = await googlePlaceService.getPlaceDetails(
          currentExpandedPlace.place_id,
        );

        if (address) {
          const addressResponse = await AddressApi.createAddress({
            ...address,
            point: {
              type: 'Point',
              coordinates: [
                currentExpandedPlace.geometry?.location?.lng() ?? 0,
                currentExpandedPlace.geometry?.location?.lat() ?? 0,
              ],
            },
          });
          const response = await CfsApi.create();
          await CfsApi.updateAddressInfo(
            response.data._id as string,
            addressResponse.data._id as string,
          );
          await fetchAssignedUnitShifts();
          setCurrentExpandedPlace(null);
          setCreatedCfsId(response.data._id as string);
          setCfsModalOpen(true);
        }
      } catch (err: any) {
        updateToast({ open: true, message: err.message });
      }
    }
  };

  const expandedPlaceMarker: IMarker | null = useMemo<IMarker | null>(() => {
    return currentExpandedPlace
      ? {
          key: String(NEW_CFS_MARKER_ID),
          info: (
            <Button
              color="error"
              variant="contained"
              onClick={handleCfsCreate}
              sx={{ m: 2 }}
            >
              Create CFS
            </Button>
          ),
          title: 'New CFS',
          position: {
            lng: currentExpandedPlace?.geometry?.location?.lng() ?? 0,
            lat: currentExpandedPlace?.geometry?.location?.lat() ?? 0,
          },
          icon: <IcMarkerCfs color={colors.warning.main} />,
        }
      : null;
  }, [currentExpandedPlace]);

  const handleCloseMarkerInfo = (key: string) => {
    if (key === NEW_CFS_MARKER_ID) {
      setCurrentExpandedPlace(null);
    }
  };

  const handleRightClick = async (e: MapMouseEvent) => {
    const { lat, lng } = e.detail.latLng ?? {};
    if (lat && lng) {
      const place = await googlePlaceService.geocodeCoordinates(lat, lng);

      if (place.geometry && place.geometry.location) {
        place.geometry.location.lat = () => lat;
        place.geometry.location.lng = () => lng;
      }

      setCurrentExpandedPlace(place);
    }
  };
  const handleResetMap = useCallback(() => {
    if (mapCenter) {
      // Force a reset by incrementing the trigger
      setResetTrigger((prev) => prev + 1);
    }
  }, [mapCenter]);

  const handleHideToggle = () => {
    setIsUnitShiftModalOpen((v) => {
      localStorage.setItem(LocalStorageItems.UnitShiftListOpen, String(!v));
      return !v;
    });
  };

  return (
    <Page title="Unit and unit shifts management" style={{ paddingTop: 0 }}>
      <Grid container>
        <Grid
          xs={isUnitShiftModalOpen ? 8 : 11.5}
          md={isUnitShiftModalOpen ? 8 : 11.7}
          lg={isUnitShiftModalOpen ? 8 : 11.75}
          item
        >
          <FireHydrantMap
            key={resetTrigger}
            center={mapCenter}
            onCloseMarkerInfo={handleCloseMarkerInfo}
            defaultZoom={12}
            markers={filteredCfsMarkers // Use filtered CFS markers here
              .concat(filteredUnitShiftMarkers)
              .concat(cfsMapMarkers)
              .concat(expandedPlaceMarker ? [expandedPlaceMarker] : [])}
            onHandleMapTypeIdChange={handleMapTypeIdChange}
            showContextMenu={true}
            showStreetViewControl={false}
            clickableIcons={false}
            onContextmenu={handleRightClick}
            style={{ height: '100vh' }}
          >
            {!!cfsId && (
              <ExpandControl onCondense={handleExpandMap} isExpanded />
            )}
            <ResetMapControl onReset={handleResetMap} />
            <SearchFilterControl />
          </FireHydrantMap>
        </Grid>
        <Grid
          xs={isUnitShiftModalOpen ? 4 : 0.5}
          md={isUnitShiftModalOpen ? 4 : 0.3}
          lg={isUnitShiftModalOpen ? 4 : 0.25}
          height="100vh"
          item
        >
          <Box display="flex" flexDirection="row" height="100vh">
            <HideButton
              sx={{ minWidth: isUnitShiftModalOpen ? '' : '100%' }}
              variant="contained"
              onClick={handleHideToggle}
            >
              {isUnitShiftModalOpen ? (
                <IcArrowLeft style={{ marginTop: 10 }} />
              ) : (
                <IcArrowRight style={{ marginTop: 10 }} />
              )}
            </HideButton>
            {isUnitShiftModalOpen && (
              <Box display="flex" flex={1} overflow="hidden">
                <UnitShiftList cfsId={cfsId} isUnitShiftsPage />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <UnitShiftListModal
        width="100%"
        cfsId={createdCfsId}
        open={cfsModalOpen}
        onClose={() => {
          setCfsModalOpen(false);
          setCreatedCfsId('');
        }}
        fetchActiveCFSList={() => {}}
      />
    </Page>
  );
};

const UnitShiftScreen = () => {
  const [queryParams] = useSearchParams();
  const cfsId = queryParams.get('cfsId') || undefined;

  return (
    <CFSProvider cfsId={cfsId}>
      <UnitShiftProvider>
        <UnitShifts />
      </UnitShiftProvider>
    </CFSProvider>
  );
};

export default UnitShiftScreen;
