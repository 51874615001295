import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const RequiredUnitFormRoot = styled(Stack)(() => ({
  '& form': {
    width: '100%',
    padding: 15,
  },
  '& .ic-plus': {
    width: 18,
    marginRight: 10,
    marginLeft: -8,
    color: colors.error.main,
  },
  '& .ic-close': {
    width: 20,
    color: colors.error.main,
  },
}));

export const EditableRequiredUnitsRoot = styled(Stack)(() => ({
  // flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  overflow: 'hidden',

  '&.float-right': {
    '& .w-full': {
      justifyContent: 'flex-end',
    },
  },

  '& .MuiIconButton-root': {
    padding: '6px 2px',
  },

  '& .w-full': {
    width: 400,
    overflow: 'visible',
  },

  '& .slider': {},

  '& .slide-item': {},

  '& .react-multiple-carousel__arrow': {
    '&:disabled': {
      '&.react-multiple-carousel__arrow--right': {
        '&:before': {
          color: colors.grey[20],
        },
      },
      '&.react-multiple-carousel__arrow--left': {
        '&:before': {
          color: colors.grey[20],
        },
      },
    },
  },

  '& .react-multiple-carousel__arrow--right, .react-multiple-carousel__arrow--left':
    {
      minWidth: 24,
      padding: 0,
      borderRadius: 0,
      backgroundColor: 'white',

      '&::before': {
        color: colors.error.main,
      },
    },
  '& .react-multiple-carousel__arrow--right': {
    right: -20,
  },
  '& .react-multiple-carousel__arrow--left': {
    left: -30,
  },
}));
