import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import {
  IKase,
  IKaseCfsLink,
  IKaseCreateRequest,
  IKaseMasterName,
  IDisposition,
  INarrative,
  INarrativeRequest,
  IKaseRejectRequest,
  IKaseMasterVehicle,
  IPriorityForm,
  IOffense,
  IOffenseRequest,
  IKaseInvolvement,
  IMasterOrganizationRequest,
  IMasterNameOrganization,
  IIncidentInfoForm,
  IMasterNamePerson,
  IArrestee,
  IKaseKaseLink,
  ICTVCode,
  IArresteeRequest,
  IKaseSentForApprovalRequest,
  ITask,
} from '@/models';
import { IDomesticIncidentReport } from '@/models/domestic.incident.report';
import { INote } from '@/models/note';

import { http } from './http';
import { IGetParams, IListResponse, IPaginatedListResponse } from './types';

export const KaseApi = {
  create: async (data: IKaseCreateRequest): Promise<AxiosResponse<IKase>> => {
    return http.post(`api/${ApiVersion.V1}/kases`, data);
  },
  createFromCsf: async (
    data: IKaseCreateRequest,
    cfsId: string,
  ): Promise<AxiosResponse<IKase>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/create-from-cfs/${cfsId}`,
      data,
    );
  },
  update: async (
    id: string,
    data: Partial<IKaseCreateRequest>,
  ): Promise<AxiosResponse<IKase>> => {
    return http.put(`api/${ApiVersion.V1}/kases/${id}`, data);
  },
  save: async (data: IKaseCreateRequest): Promise<AxiosResponse<IKase>> => {
    return data._id ? KaseApi.update(data._id, data) : KaseApi.create(data);
  },
  getOne: async (id: string): Promise<AxiosResponse<IKase>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${id}`);
  },
  list: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<IKase>>> => {
    return http.get(`api/${ApiVersion.V1}/kases`, { params });
  },
  listCtvCodes: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICTVCode>>> => {
    return http.get(`api/${ApiVersion.V1}/ctv-codes`, { params });
  },
  getLinkedMasterNames: async (
    kaseId: string,
    params: IGetParams,
  ): Promise<AxiosResponse<IKaseMasterName[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/master-names`, {
      params,
    });
  },
  addDisposition: async (
    kaseId: string,
    data: Omit<IDisposition, 'createdBy' | 'createdAt'>,
  ): Promise<AxiosResponse<IKase>> => {
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/dispositions`, data);
  },
  getLinkedCFS: async (
    id: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IKaseCfsLink[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${id}/cfs`, { params });
  },
  linkCFSToKase: async (
    kaseId: string,
    cfsId: string,
  ): Promise<AxiosResponse<IKaseCfsLink>> => {
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/cfs/${cfsId}`);
  },
  unlinkCFSFromKase: async (
    kaseId: string,
    cfsId: string,
  ): Promise<AxiosResponse<IKaseCfsLink>> => {
    return http.delete(`api/${ApiVersion.V1}/kases/${kaseId}/cfs/${cfsId}`);
  },
  getLinkedKases: async (
    id: string,
    params: IGetParams,
  ): Promise<AxiosResponse<IKaseKaseLink[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${id}/links`, { params });
  },
  getLinkedTasks: async (
    id: string,
    params: IGetParams,
  ): Promise<AxiosResponse<ITask[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${id}/relatedTasks`, {
      params,
    });
  },
  linkKaseToKase: async (
    kaseId: string,
    kaseLinkId: string,
  ): Promise<AxiosResponse<IKaseKaseLink>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/${kaseId}/links/${kaseLinkId}`,
    );
  },
  unlinkKaseFromKase: async (
    kaseId: string,
    kaseLinkId: string,
  ): Promise<AxiosResponse<IKaseKaseLink>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/links/${kaseLinkId}`,
    );
  },
  getKaseLogs: async (
    kaseId: string,
    params: IGetParams,
  ): Promise<AxiosResponse<any>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/logs`, { params });
  },
  // --------------- narratives ---------------------\\
  saveNarative: async (
    kaseId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return data._id
      ? KaseApi.updateNarrative(kaseId, data._id, data)
      : KaseApi.createNarrative(kaseId, data);
  },
  createNarrative: async (
    kaseId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/narratives`, data);
  },
  updateNarrative: async (
    kaseId: string,
    narrativeId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return http.put(
      `api/${ApiVersion.V1}/kases/${kaseId}/narratives/${narrativeId}`,
      data,
    );
  },
  getLinkedNarratives: async (
    kaseId: string,
    params: IGetParams = { sort: JSON.stringify({ createdAt: -1 }) },
  ): Promise<AxiosResponse<INarrative[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/narratives`, {
      params,
    });
  },
  deleteNarrative: async (
    kaseId: string,
    narrativeId: string,
  ): Promise<AxiosResponse<IKaseCfsLink>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/narratives/${narrativeId}`,
    );
  },
  // --------------- masternames ---------------------\\
  getKaseMasterNames: async (
    kaseId: string,
  ): Promise<AxiosResponse<IKaseMasterName[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/master-names`);
  },
  addKaseMasterName: async (
    kaseId: string,
    nameId: string,
  ): Promise<AxiosResponse<IKaseMasterName>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}`,
      {},
    );
  },
  updateKaseMasterName: async (
    kaseId: string,
    nameId: string,
    data: { relationship: IKaseInvolvement },
  ): Promise<AxiosResponse<IKaseMasterName>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}`,
      data,
    );
  },
  deleteKaseMasterName: async (
    kaseId: string,
    nameId: string,
  ): Promise<AxiosResponse<IKaseMasterName>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}`,
    );
  },
  getKaseMasterName: async (
    kaseId: string,
    nameId: string,
  ): Promise<AxiosResponse<IKaseMasterName>> => {
    return http.get(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}`,
    );
  },
  createMasterNameNote: (
    kaseId: string,
    nameId: string,
    description: string,
  ): Promise<AxiosResponse<INote>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}/notes`,
      {
        description,
      },
    );
  },

  // --------------- masterName kases---------------------\\
  saveMasterOrganization: async (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return data._id
      ? KaseApi.updateMasterOrganization(data._id, data)
      : KaseApi.addMasterOrganization(data);
  },
  addMasterOrganization: async (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.post(`api/${ApiVersion.V1}/master-names/organizations/`, data);
  },
  updateMasterOrganization: async (
    organizationId: string,
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.put(
      `api/${ApiVersion.V1}/master-names/organizations/${organizationId}`,
      data,
    );
  },
  getMasterOrganization: async (
    organizationId: string,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-names/organizations/${organizationId}`,
    );
  },
  deleteMasterOrganization: async (
    kaseId: string,
    nameId: string,
  ): Promise<AxiosResponse<IKaseMasterName>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-names/${nameId}`,
    );
  },
  addUnknownOrganization: async (): Promise<
    AxiosResponse<IMasterNameOrganization>
  > => {
    return http.post(
      `api/${ApiVersion.V1}/master-names/organizations/unknown/`,
    );
  },
  addUnknownPerson: async (): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.post(`api/${ApiVersion.V1}/master-names/persons/unknown/`);
  },
  //
  // --------------- update case status ---------------------\\
  submitKase: async (
    kaseId: string,
    data: IKaseSentForApprovalRequest,
  ): Promise<AxiosResponse<IKase>> => {
    return http.patch(`api/${ApiVersion.V1}/kases/${kaseId}/submit`, data);
  },
  rejectKase: async (
    kaseId: string,
    data: IKaseRejectRequest,
  ): Promise<AxiosResponse<IKase>> => {
    return http.patch(`api/${ApiVersion.V1}/kases/${kaseId}/reject`, data);
  },
  approveKase: async (kaseId: string): Promise<AxiosResponse<IKase>> => {
    return http.patch(`api/${ApiVersion.V1}/kases/${kaseId}/approve`);
  },
  reopenKase: async (kaseId: string): Promise<AxiosResponse<IKase>> => {
    return http.patch(`api/${ApiVersion.V1}/kases/${kaseId}/reopen`);
  },
  reassignKase: async (
    kaseId: string,
    supervisorId: string,
    comment: string,
  ): Promise<AxiosResponse<IKase>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/assign/${supervisorId}`,
      { comment },
    );
  },
  togglePriority: async (
    kaseId: string,
    data?: IPriorityForm,
  ): Promise<AxiosResponse<IKase>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/toggle-priority`,
      data,
    );
  },
  // --------------- vehicles ---------------------\\
  getKaseMasterVehicles: async (
    kaseId: string,
  ): Promise<AxiosResponse<IKaseMasterVehicle[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/master-vehicles`);
  },
  addKaseMasterVehicle: async (
    kaseId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<IKaseMasterVehicle>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-vehicles/${vehicleId}`,
    );
  },
  deleteKaseMasterVehicle: async (
    kaseId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<IKaseMasterVehicle>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/master-vehicles/${vehicleId}`,
    );
  },
  // --------------- offenses ---------------------\\
  getOffenses: async (
    kaseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IOffense>>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/offenses`, {
      params,
    });
  },
  createOffense: async (
    kaseId: string,
    data: IOffenseRequest,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/offenses`, data);
  },
  updateOffense: async (
    kaseId: string,
    { _id: offenseId, ...data }: IOffenseRequest,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.put(
      `api/${ApiVersion.V1}/kases/${kaseId}/offenses/${offenseId}`,
      data,
    );
  },
  deleteOffense: async (
    kaseId: string,
    offenseId: string,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/offenses/${offenseId}`,
    );
  },
  updateIncidentInfo: async (kaseId: string, data: IIncidentInfoForm) => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/incident-info`,
      data,
    );
  },
  // --------------- arrestee ---------------------\\
  getArrestees: async (
    kaseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<IArrestee>>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/arrestees`, {
      params,
    });
  },
  updateArrestee: async (
    kaseId: string,
    masterNameId: string,
    data: IArresteeRequest,
  ): Promise<AxiosResponse<IArrestee>> => {
    return http.put(
      `api/${ApiVersion.V1}/kases/${kaseId}/arrestees/${masterNameId}`,
      data,
    );
  },
  getArresteeOffenseHistory: async (
    kaseId: string,
    arresteeId: string,
  ): Promise<AxiosResponse<any[]>> => {
    return http.get(
      `api/${ApiVersion.V1}/kases/${kaseId}/arrestees/${arresteeId}/offense-history`,
    );
  },
  // --------------- Domestic incident report ---------------------\\
  print: async (
    kaseId: string,
    data: IDomesticIncidentReport,
  ): Promise<AxiosResponse<any>> => {
    return http.post(
      `api/${ApiVersion.V1}/kases/${kaseId}/print-domestic-incident-report`,
      data,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    );
  },

  saveDraft: async (
    kaseId: string,
    data: IDomesticIncidentReport,
    draftId?: string,
  ): Promise<AxiosResponse<IKase>> => {
    if (draftId) {
      return http.put(
        `api/${ApiVersion.V1}/kases/${kaseId}/drafts/${draftId}`,
        data,
      );
    }
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/drafts`, data);
  },
  deleteDraft: async (
    kaseId: string,
    draftId?: string,
  ): Promise<AxiosResponse<IKase>> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/drafts/${draftId}`,
    );
  },
};
