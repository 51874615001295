import { IcFire, IcMedicalCross, IcPolice } from '@/assets/images';
import { AgencyType, AssignmentStatus, TSeverity } from '@/models';

export const requiredUnitFields = [
  {
    name: 'Police',
    agencyType: AgencyType.POLICE,
    icon: IcPolice,
  },
  {
    name: 'Fire',
    agencyType: AgencyType.FIRE,
    icon: IcFire,
  },
  {
    name: 'EMS',
    agencyType: AgencyType.EMS,
    icon: IcMedicalCross,
  },
];

export const activeCFSTableColumns = [
  {
    key: '_id',
    label: 'CFS ID',
  },
  {
    key: 'shortDescription',
    label: 'Short Description',
  },
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'incidentCodes',
    label: 'Incident Codes',
  },
  {
    key: 'assignedUnitShifts',
    label: 'Unit assignment',
  },
  {
    key: 'createdAt',
    label: 'Time of call',
  },
  {
    key: 'duration',
    label: 'Live Timer',
  },
  {
    key: 'action',
    label: '',
  },
];

export const filterMenuFormFields = [
  {
    name: 'assignmentStatus',
    type: 'autocomplete',
    label: 'Status',
    items: [
      {
        label: 'New',
        value: AssignmentStatus.NEW,
      },
      {
        label: 'Ready for Dispatch',
        value: 'isReadyToDispatch',
      },
      {
        label: 'Partially Assigned',
        value: AssignmentStatus.PARTIAL_ASSIGNED,
      },
      {
        label: 'Assigned',
        value: AssignmentStatus.ASSIGNED,
      },
    ],
  },
  {
    name: 'severity',
    type: 'autocomplete',
    label: 'Severity',
    items: [
      {
        label: 'HIGH',
        value: TSeverity.HIGH,
      },
      {
        label: 'MEDIUM',
        value: TSeverity.MEDIUM,
      },
      {
        label: 'LOW',
        value: TSeverity.LOW,
      },
    ],
  },
];
