import { Button, Stack, Typography, styled } from '@mui/material';

import { IcPencil, IcScan } from '@/assets/images';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { colors } from '@/theme/variables';

import { TabNamesNextPaths, TabNamesScreens } from '../data';

const BlockNavLink = styled(Button)(({ theme }) => ({
  outline: `1px solid ${colors.grey[20]}`,
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '215px',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 500,
  textDecoration: 'none',
  borderRadius: theme.spacing(1),
  fontSize: '0.9375rem',
  lineHeight: 2.3,
  transition: 'all 0.2s ease-out',
  marginBottom: 16,
  color: colors.grey[100],
  '&:hover': {
    outline: `2px solid ${colors.error.main}}`,
  },
}));

export const MasterNameDriverLicense = () => {
  const { updateCurrentScreen } = useTabLayoutContext();

  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterAddNameDetails);
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.driverLicense}>
      <PageBackHeader
        title="Driver's license / ID card information"
        goBack={onGoBack}
      />
      <Stack p={3}>
        <Typography>
          Scan the barcode on driver’s license or ID card.
        </Typography>
        <Typography>
          You can also manually edit the details if necessary.
        </Typography>
      </Stack>

      <Stack direction="row" gap={3} px={3}>
        <BlockNavLink
          onClick={() =>
            updateCurrentScreen(TabNamesScreens.MasterAddDriverLicense)
          }
        >
          <IcPencil></IcPencil>
          Enter manually
        </BlockNavLink>
        <BlockNavLink>
          <IcScan></IcScan>
          Scan
        </BlockNavLink>
      </Stack>
    </TabAnimationWrapper>
  );
};
