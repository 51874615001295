import { useEffect, useRef } from 'react';
import {
  Control,
  FieldArrayPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';

import { Button, Grid, IconButton, Stack, StackProps } from '@mui/material';

import { IcDeleteTrash } from '@/assets/images';
import { IRenderFormFieldProps, RenderFormField } from '@/components';
import { colors } from '@/theme/variables';

interface InputCollectionProps extends StackProps {
  name: FieldArrayPath<FieldValues>;
  control: Control<FieldValues, any>;
  addLabel: string;
  InputFields: IRenderFormFieldProps[];
  populateByDefault?: boolean;
}
export const InputCollection = ({
  name,
  control,
  addLabel,
  InputFields,
  populateByDefault,
  ...rest
}: InputCollectionProps) => {
  const { remove, append, fields } = useFieldArray({
    control,
    name,
  });

  const handleAddInput = () => {
    append(
      InputFields.reduce((acc, item) => {
        return { ...acc, [item.name]: '' };
      }, {}),
    );
  };
  const alreadyInitialized = useRef(false);

  useEffect(() => {
    if (!alreadyInitialized.current && populateByDefault && !fields.length) {
      alreadyInitialized.current = true;
      handleAddInput();
    }
  }, []);

  return (
    <Stack mx={1} justifyContent="center" {...rest}>
      {fields.map((inputField, index: number) => (
        <Grid container columnSpacing={1} key={`row-${inputField.id}`}>
          {InputFields.map((field) => (
            <Grid
              key={`${field.name}-${inputField.id}`}
              item
              flex="1"
              maxWidth="45%"
            >
              <RenderFormField
                {...field}
                name={`${name}.${index}.${field.name}`}
              />
            </Grid>
          ))}
          <Grid mt={1} item>
            <IconButton
              onClick={() => remove(index)}
              sx={{ color: colors.error.main, mt: 2, alignSelf: 'center' }}
            >
              <IcDeleteTrash />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        color="error"
        variant="text"
        onClick={handleAddInput}
        sx={{
          width: 'fit-content',
          padding: 0,
          whiteSpace: 'nowrap',
          my: 'auto',
        }}
      >
        + Add {addLabel}
      </Button>
    </Stack>
  );
};
