import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';

import { CfsApi, MasterNameApi } from '@/api';
import { IcArrowDown, IcCheckSuccess } from '@/assets/images';
import { Avatar, Chip, PageHeader } from '@/components';
import { withLoader, WithLoaderProps } from '@/hocs';
import { useCFSContext } from '@/hooks';
import {
  AssignedUnitShiftStatus,
  AssignmentStatus,
  IAccountSettings,
  MasterNameType,
} from '@/models';
import {
  ConfirmTypes,
  useDataStore,
  useToastStore,
  useUserStore,
} from '@/store';
import colors from '@/theme/variables/colors';

import { CFSAssignmentStatusItem } from './CFSAssignmentStatusItem';
import { CFSCallerItem } from './CFSCallerItem';
import { CFSReporterTimeItem } from './CFSReporterTimeItem';
import ClosureReasonModal from './ClosureReasonModal';
import { CFSCreateRmsMenuItem } from '../../List/components/CFSCreateRmsMenuItem';

const CFSHeader = ({
  hideLoader,
  showLoader,
  isQuickView,
  accountSettings,
  ...rest
}: {
  cfsId?: string;
  isQuickView?: boolean;
  accountSettings: IAccountSettings | undefined;
} & WithLoaderProps) => {
  const urlParams = useParams();
  const cfsId = rest.cfsId || String(urlParams.cfsId);
  const { cfs, ablyCfsChannel, updateCFS } = useCFSContext();
  const { updateToast } = useToastStore();
  const { cfsCloseReasons } = useDataStore();
  const { account } = useUserStore();
  const isEnabledUnderFireFeature =
    account?.settings?.features?.isEnabledUnderFire;

  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [organizationName, setOrganizationName] = useState<string>('');

  const fullName = cfs?.createdBy?.fullName ?? '';
  const openActionsMenu = Boolean(anchorEl);
  const closedReason = cfs?.isClosed
    ? cfsCloseReasons.find(({ _id }) => _id === cfs.closeReason)
    : null;
  const showRTDButton =
    !account?.settings.autoCFSRTD &&
    !cfs?.isReadyToDispatch &&
    cfs?.addressInfo?.address?._id &&
    cfs.assignmentStatus === AssignmentStatus.NEW;

  const navigate = useNavigate();
  const hasOnSceneUnit = cfs?.assignedUnitShifts.find(
    (assignedUnit) => assignedUnit.status === AssignedUnitShiftStatus.ON_SCENE,
  );

  const canMarkFireUnderControl =
    !cfs?.fireControlled &&
    accountSettings?.features?.isEnabledUnderFire &&
    hasOnSceneUnit;

  useEffect(() => {
    if (cfs?.assignmentStatus) {
      ablyCfsChannel?.publish('', { assignmentStatus: cfs.assignmentStatus });
    }
  }, [cfs?.assignmentStatus]);

  useEffect(() => {
    if (cfs?.reporter?.callerPhoneNumber) {
      handleOrganizationPhoneNumber(cfs?.reporter.callerPhoneNumber);
    }
  }, [cfs?.reporter?.callerPhoneNumber]);

  const handleOrganizationPhoneNumber = async (pNumber: string) => {
    try {
      const filter = {
        phoneNumbers: {
          $elemMatch: {
            phoneNumber: pNumber,
          },
        },
        type: MasterNameType.ORGANIZATION,
      };

      const { data }: any = await MasterNameApi.getMasterNames({
        filter: JSON.stringify(filter),
        limit: 1,
      });

      setOrganizationName(data.results?.[0]?.name || '');
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };
  const handleCloseCFS = () => {
    setOpenModal(true);
    handleCloseActionsMenu();
  };

  const handleReopenCFS = () => {
    if (cfsId) {
      CfsApi.reopenCFS(cfsId)
        .then(() => {
          updateCfsClosedState(false, '');
          updateToast({
            type: ConfirmTypes.SUCCESS,
            open: true,
            message: 'CFS record re-opened successfully',
          });
        })
        .catch((error: AxiosError) => {
          updateToast({
            type: ConfirmTypes.ERROR,
            open: true,
            message: `ERROR,CFS record CANNOT be re-opened: ${error.message}`,
          });
        })
        .finally(() => setAnchorEl(null));
    }
  };
  const onClose = () => {
    setOpenModal(false);
  };

  const handleOpenActionsMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionsMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeReporterTime = async (v: string) => {
    try {
      ablyCfsChannel?.publish('cfsForm', { reportTime: v });
      if (cfs)
        updateCFS({ ...cfs, reporter: { ...cfs.reporter, reportTime: v } });
      await CfsApi.update(String(cfsId), {
        reporter: { reportTime: v },
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleCreateNewCFS = async () => {
    try {
      const response = await CfsApi.create();
      window.location.replace(
        `${window.location.origin}/cfs/${response.data._id}`,
      );
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const updateCfsClosedState = (isClosed: boolean, closeReason: string) => {
    if (!cfs) return;
    updateCFS({ ...cfs, isClosed, closeReason });
  };

  const setCfsRTD = async () => {
    try {
      showLoader();
      await CfsApi.setCFSRTD(cfsId ?? '');
      if (cfs) updateCFS({ ...cfs, isReadyToDispatch: true });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const handleFireUnderControl = async () => {
    try {
      showLoader();
      const responseCfs = await CfsApi.makeFireUnderControl(cfsId ?? '');
      if (cfs)
        updateCFS({ ...cfs, fireControlled: responseCfs.data.fireControlled });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  const renderStatusChip = () => {
    if (cfs?.isClosed) {
      return (
        <Chip
          label="Closed"
          className="cfs-assignment-status"
          sx={{
            backgroundColor: colors.grey[10],
            color: colors.grey[90],
          }}
        />
      );
    } else {
      if (isEnabledUnderFireFeature && cfs?.fireControlled) {
        return (
          <Chip
            label="Fire controlled"
            sx={{
              backgroundColor: '#97CC6D',
              color: colors.white,
            }}
          />
        );
      } else if (!cfs?.fireControlled && cfs?.assignmentStatus) {
        return (
          <CFSAssignmentStatusItem
            assignmentStatus={cfs.assignmentStatus}
            isReadyToDispatch={cfs.isReadyToDispatch}
            className="cfs-assignment-status"
          />
        );
      } else {
        return null;
      }
    }
  };

  const renderMarkFireControlledButton = () => (
    <>
      {canMarkFireUnderControl && (
        <Button
          onClick={handleFireUnderControl}
          color="error"
          variant="text"
          size="small"
          style={{ textDecoration: 'underline' }}
        >
          Mark Fire Under Control
        </Button>
      )}
    </>
  );

  const renderRFDButton = () => (
    <>
      {showRTDButton && (
        <Button
          variant="text"
          color="error"
          sx={{ ml: -2, textDecoration: 'underline' }}
          onClick={setCfsRTD}
        >
          Mark as Ready for Dispatch
        </Button>
      )}
    </>
  );

  const renderCloseReason = () => (
    <>
      {!!closedReason && (
        <Chip
          label={closedReason.reason}
          className="cfs-assignment-status"
          sx={{
            backgroundColor: colors.grey[10],
            color: colors.grey[90],
          }}
        />
      )}
    </>
  );

  const renderEmailSentAt = () => (
    <>
      {cfs?.emailSentAt && (
        <Tooltip
          title={`Last email sent at: ${new Date(
            cfs?.emailSentAt,
          ).toLocaleDateString()}`}
        >
          <Box sx={{ mt: 0.75 }}>
            <IcCheckSuccess />
          </Box>
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      <PageHeader
        onBack={isQuickView ? () => navigate('/cfs-active') : undefined}
      >
        <Stack flex={1} direction="row" alignItems="center" columnGap={2.5}>
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenActionsMenu}
            endIcon={<IcArrowDown />}
            className="cfs-action-button"
          >
            Actions
          </Button>

          <Typography variant="inherit" sx={{ fontSize: '1.313rem' }}>
            {cfs?.number}
          </Typography>

          {renderStatusChip()}
          {renderCloseReason()}
          {renderEmailSentAt()}
          {renderMarkFireControlledButton()}
          {renderRFDButton()}
        </Stack>

        <Stack direction="row" alignItems="center" columnGap={2.5}>
          {!!cfs?.reporter.callerPhoneNumber && (
            <>
              {organizationName ? (
                <Tooltip title={organizationName}>
                  <Box>
                    <CFSCallerItem
                      phoneNumber={cfs?.reporter.callerPhoneNumber}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <CFSCallerItem phoneNumber={cfs?.reporter.callerPhoneNumber} />
              )}
              <Divider orientation="vertical" flexItem />
            </>
          )}

          <CFSReporterTimeItem
            reporterTime={cfs?.reporter.reportTime}
            onAccept={handleChangeReporterTime}
          />

          <Divider orientation="vertical" flexItem />

          {!!fullName && (
            <Avatar className="cfs-detail-avatar" name={fullName} showTooltip />
          )}
        </Stack>
      </PageHeader>

      {openModal && cfs?._id && (
        <ClosureReasonModal
          cfsId={cfs?._id}
          open={openModal}
          onClose={onClose}
          updateCfsClosedState={updateCfsClosedState}
        />
      )}

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={openActionsMenu}
        onClose={handleCloseActionsMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCreateNewCFS}>Save and New</MenuItem>
        <CFSCreateRmsMenuItem isClosed={cfs?.isClosed} cfsId={cfs?._id} />
        {cfs?.isClosed ? (
          <MenuItem onClick={handleReopenCFS}>ReOpen CFS record</MenuItem>
        ) : (
          <MenuItem onClick={handleCloseCFS}>Close CFS record</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default withLoader(CFSHeader);
