import { FC } from 'react';

import { Grid } from '@mui/material';
import moment from 'moment';

import { AppModal, BaseModalProps, IViewItem, ViewItem } from '@/components';
import { DATE_TIME_FORMAT_WITH_SECONDS } from '@/config';
import { ICallerLocation } from '@/models';
import { getItemValueFromObject, metersToMilesAndFeet } from '@/utils';

interface RapidSOSInfoModalProps {
  location?: ICallerLocation;
}

const RapidSOSInfoFields = {
  items: [
    {
      value: 'altitude',
      label: 'Altitude',
    },
    {
      value: 'application',
      label: 'Application',
    },
    {
      value: 'call_start_time',
      label: 'Call start time',
    },
    {
      value: 'caller_id',
      label: 'Caller ID',
    },
    {
      value: 'caller_name',
      label: 'Caller name',
    },
    {
      value: 'created_time',
      label: 'Created time',
    },
    {
      value: 'format_version',
      label: 'Format version',
    },
    {
      value: 'latitude',
      label: 'Latitude',
    },
    {
      value: 'location_time',
      label: 'Location time',
    },
    {
      value: 'longitude',
      label: 'Longitude',
    },
    {
      value: 'positioning_method',
      label: 'Positioning method',
    },
    {
      value: 'uncertainty_confidence',
      label: 'Uncertainty confidence',
    },
    {
      value: 'uncertainty_confidence_pdf',
      label: 'Uncertainty confidence PDF',
    },
    {
      value: 'uncertainty_radius',
      label: 'Uncertainty radius',
    },
  ],
};

export const RapidSOSInfoModal: FC<BaseModalProps & RapidSOSInfoModalProps> = ({
  open,
  onClose,
  location,
}) => {
  const getValue = (item: IViewItem) => {
    if (!location) {
      return '';
    }

    const itemName = item.value as string;

    let itemValue = getItemValueFromObject(location, itemName);
    if (itemName.includes('time') && itemValue) {
      itemValue = moment(Number(itemValue)).format(
        `${DATE_TIME_FORMAT_WITH_SECONDS}`,
      );
    } else if (itemName === 'uncertainty_confidence' && itemValue) {
      itemValue = `${itemValue}%`;
    } else if (itemName === 'uncertainty_radius' && itemValue) {
      itemValue = metersToMilesAndFeet(Number(itemValue));
    }

    return itemValue ?? '-';
  };

  return (
    <AppModal open={open} title="RapidSOS Raw Data" onClose={onClose}>
      {location && (
        <Grid xs={12} container>
          {RapidSOSInfoFields.items.map((item) => (
            <ViewItem
              key={item.label}
              label={item.label}
              md={6}
              value={getValue(item)}
            />
          ))}
        </Grid>
      )}
    </AppModal>
  );
};
