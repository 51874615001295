import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { CfsApi, DataApi, TaskApi } from '@/api';
import {
  ICFSCloseReason,
  IIncidentCode,
  IPolygonLayer,
  ITag,
  ITaskStatus,
  ITaskType,
  IUnitType,
  IVehicleMake,
} from '@/models';

interface DataStoreState {
  makes: IVehicleMake[];
  unitTypes: IUnitType[];
  incidentCodes: IIncidentCode[];
  beats: IPolygonLayer[];
  cfsCloseReasons: ICFSCloseReason[];
  taskTypes: ITaskType[];
  taskTags: ITag[];
  taskStatuses: ITaskStatus[];
}

interface DataStore extends DataStoreState {
  updateData: (newData: Partial<DataStoreState>) => void;
  loadData: () => void;
}

export const useDataStore = create<
  DataStore,
  [['zustand/devtools', never], ['zustand/persist', DataStoreState]]
>(
  devtools(
    persist(
      (set) => ({
        incidentCodes: [],
        makes: [],
        unitTypes: [],
        beats: [],
        cfsCloseReasons: [],
        taskTypes: [],
        taskTags: [],
        taskStatuses: [],
        updateData: (newData: Partial<DataStoreState>) =>
          set((state) => ({
            ...state,
            ...newData,
          })),
        loadData: () => {
          CfsApi.getCloseReasons().then((res) => {
            set((state) => ({ ...state, cfsCloseReasons: res.data }));
          });
          DataApi.getVehicleMakes().then((res) => {
            set((state) => ({ ...state, makes: res.data }));
          });
          DataApi.getUnitTypes().then((res) => {
            set((state) => ({ ...state, unitTypes: res.data }));
          });
          DataApi.getIncidentCodes().then((res) => {
            set((state) => ({ ...state, incidentCodes: res.data }));
          });
          TaskApi.getTaskTypes().then((res) => {
            set((state) => ({ ...state, taskTypes: res.data }));
          });
          TaskApi.getTaskStatuses().then((res) => {
            set((state) => ({ ...state, taskStatuses: res.data }));
          });
          TaskApi.getTaskTags().then((res) => {
            set((state) => ({ ...state, taskTags: res.data }));
          });
        },
      }),
      {
        name: 'dataStore',
        version: 1,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
