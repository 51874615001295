import { FC, useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, IconButton, Stack } from '@mui/material';
import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';
import { useChannel } from 'ably/react';

import { CfsApi, MasterNameApi } from '@/api';
import {
  IcArrowLeft,
  IcArrowRight,
  IcLocationSelect,
  IcMinus,
  IcPlus,
  IcRoadAssistanceOff,
  IcRoadAssistanceOn,
} from '@/assets/images';
import { Page } from '@/components';
import { FireHydrantMap } from '@/components/GoogleMap';
import { defaultMapCenter } from '@/config';
import { withLoader, WithLoaderProps } from '@/hocs';
import { useCFSContext, useUnitShiftContext } from '@/hooks';
import { MasterNamesContextProvider } from '@/layouts/TabLayout/MasterNames';
import { MasterVehicleContextProvider } from '@/layouts/TabLayout/MasterVehicles';
import { CFSMasterNameInvolvement, ICFS, ICFSInvolvement } from '@/models';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';

import DashboardActions from '../../components/DashboardActions';
import { FirstResponderModal } from '../../components/FirstResponderModal';
import FirstResponderTabs from '../../components/FirstResponderTabs';
import { SIDEBAR_WIDTH, useStyles } from '../../components/sharedStyles';
import BackupRequestedModalContent from '../../modals/BackupRequestedModalContent';
import CfsDetailsModalContent from '../../modals/CFSDetailsModalContent';
import NewCfsAssignedModalContent from '../../modals/NewCfsAssignedModalContent';
import { findUnitShiftByUserId } from '../../shared/findUnitShiftByUserId';
import { initialModalState, ModalState } from '../../shared/modalState';
import { shouldDisplayNewCfsModal } from '../../shared/newCfsAssigned';
import AllUnitsTab from '../../tabs/AllUnitsTab';
import CarStopCfsTab from '../../tabs/CarStopCfsTab';
import CfsListTab from '../../tabs/CfsListTab';
import IncidentCfsTab from '../../tabs/IncidentCfsTab';
import BackupRequestRejectedModalContent from '../../modals/BackupRequestRejectedModalContent';
import KasesTab from '../../tabs/KasesTab';

type CFSFirstRespondersProps = {
  setActiveCfsId: (cfsId: string) => void;
};

const CFSFirstResponders: FC<WithLoaderProps & CFSFirstRespondersProps> = ({
  setActiveCfsId,
  showLoader,
  hideLoader,
}) => {
  const [cfsId] = useState<string | undefined>(undefined);
  const [assignedCfsId, setAssignedCfsId] = useState<string | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    watchPosition: true,
  });

  const map = useMap();

  const { account, user } = useUserStore();

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFullSidebar, setIsFullSidebar] = useState(false);

  const [backupRequestedCFS, setBackupRequestedCFS] = useState<{
    cfsId: string;
    cfsNumber: string;
  } | null>(null);
  const [selectedTab, setSelectedTab] = useState('cfs-list');
  const [activeCfs, setActiveCfs] = useState<ICFS | undefined>();
  const [isRoadAssistace, setIsRoadAssistace] = useState(true);
  const [mapOffset, setMapOffset] = useState(0);
  const { updateToast } = useToastStore();
  const [modalState, setModalState] = useState<ModalState>(initialModalState);
  const [rejectedUnitName, setRejectedUnitName] = useState<string>('');

  const { cfs } = useCFSContext();
  const { activeCfsMarkers, unitShiftMarkers, fetchAssignedUnitShifts } =
    useUnitShiftContext();

  const [sidebarContentWidth, setSidebarContentWidth] = useState(
    window.innerWidth - SIDEBAR_WIDTH,
  );

  const updateSidebarContentWidth = () => {
    setSidebarContentWidth(window.innerWidth - SIDEBAR_WIDTH);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSidebarContentWidth);
    return () => {
      window.removeEventListener('resize', updateSidebarContentWidth);
    };
  }, []);

  useChannel(`account:${account?._id}:unit-shift`, async (message) => {
    const { data, name } = message;

    if (name !== 'BACKUP_REQUESTED') return;

    if (name === 'BACKUP_REQUESTED' && data.userId === user?._id) {
      if (data.status === 'PENDING') {
        setBackupRequestedCFS({
          cfsId: data.cfsId,
          cfsNumber: data.cfsNumber,
        });
        toggleModal('backupRequested');
      } else if (data.status === 'REJECTED') {
        setRejectedUnitName(data.unitName);
        toggleModal('backupRequestRejected');
      }
    }
  });

  useChannel(
    `account:${account?._id}:cfs:assigned-unit-shift`,
    async (message) => {
      if (!user) return;
      if (shouldDisplayNewCfsModal(message, user)) {
        setAssignedCfsId(message.extras.headers.cfs);
        toggleModal('newCfsAssigned');
      }
    },
  );

  useEffect(() => {
    fetchAssignedUnitShifts();
  }, []);

  useEffect(() => {
    if (mapCenter && map) {
      panByOffset(mapOffset);
    }
  }, [mapOffset, mapCenter]);

  useEffect(() => {
    const offset = isSidebarOpen
      ? (window.innerWidth - SIDEBAR_WIDTH) / 4
      : -(window.innerWidth - SIDEBAR_WIDTH) / 4;
    setMapOffset(offset);
  }, [isSidebarOpen]);

  const cfsMarkers =
    activeCfsMarkers.filter((marker) => !cfsId || marker.key === cfsId) || [];
  const cfsPosition = cfsMarkers[0]?.position;

  useEffect(() => {
    if (cfsPosition) {
      setMapCenter(cfsPosition);
    }
  }, [cfsPosition]);

  const panByOffset = (offset: number) => {
    if (!map) return;
    map.panBy(offset, 0);
  };

  const locateMe = () => {
    let center = cfsPosition;
    if (coords) {
      center = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
    }
    setMapCenter({ ...center });
  };

  const updateZoom = (isZoomIn?: boolean) => {
    if (!map) return;
    const currentZoom = map.getZoom() || 1;
    const newZoomLevel = Math.min(
      22,
      Math.max(0, isZoomIn ? currentZoom + 1 : currentZoom - 1),
    );
    map.setZoom(newZoomLevel);
  };

  const setRoadAssistanceStatus = (status: boolean) => {
    setIsRoadAssistace(status);
  };

  const toggleModal = (modalName: keyof ModalState) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: !prevState[modalName],
    }));
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'cfs-list':
        return (
          <CfsListTab setActiveCfs={setActiveCfs} toggleModal={toggleModal} />
        );
      case 'all-units':
        return <AllUnitsTab setMapCenter={setMapCenter} />;
      case 'incident-cfs':
        return (
          <IncidentCfsTab
            handleBack={() => {
              setSelectedTab('cfs-list');
              setIsFullSidebar(false);
            }}
            handleSlefAssignCfs={handleSlefAssignCfs}
          />
        );
      case 'car-stop-cfs':
        return (
          <CarStopCfsTab
            handleBack={() => {
              setSelectedTab('cfs-list');
              setIsFullSidebar(false);
            }}
            handleSlefAssignCfs={handleSlefAssignCfs}
          />
        );
      case 'case':
        return (
          <KasesTab
            handleBack={() => {
              setSelectedTab('cfs-list');
              setIsFullSidebar(false);
            }}
          ></KasesTab>
        );
      default:
        return null;
    }
  };

  const answerBackupRequest = (accepted: boolean) => {
    CfsApi.responseBackup(backupRequestedCFS?.cfsId as string, accepted)
      .then(() => {
        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: accepted
            ? 'Backup request accepted'
            : 'Backup request rejected',
        });
        if (accepted) {
          navigate(`/cfs/${backupRequestedCFS?.cfsId}`);      
          setActiveCfsId(backupRequestedCFS?.cfsId as string);
          setBackupRequestedCFS(null);
        } else {
          navigate('/my-cfs');
        }      })
      .catch((error) => {
        updateToast({
          open: true,
          message: error ? error : 'Error responding to backup request',
        });
      });
  };

  const handleSlefAssignCfs = (cfsToAssign: ICFS, isFromCreate?: boolean) => {
    if (!cfsToAssign) return;
    CfsApi.assignMe(cfsToAssign._id as string)
      .then((res) => {
        const assignedcfs = res.data;
        const assignedUnitShift = findUnitShiftByUserId(
          assignedcfs.assignedUnitShifts,
          user?._id as string,
        );

        const badgeNumber =
          user?.profile?.employmentInformation?.badgeNumber || 'N/A';

        let successMessage = '';
        if (isFromCreate) {
          successMessage = assignedUnitShift
            ? `${assignedcfs.number} was successfully created and assigned to ${assignedUnitShift.unitShift.unit.name} (${badgeNumber})`
            : `${assignedcfs.number} was successfully created and assigned to ${badgeNumber}`;
        } else {
          successMessage = assignedUnitShift
            ? `${assignedUnitShift.unitShift.unit.name} (${badgeNumber}) successfully assigned to ${assignedcfs.number}.`
            : `(${badgeNumber}) successfully assigned to ${assignedcfs.number}.`;
        }

        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: successMessage,
        });
        navigate('/my-cfs');
      })

      .catch((error) => {
        updateToast({
          open: true,
          message: error ? error.message : 'Error assigning to CFS',
        });
      });
  };
  const handleCreateCfs = (type: 'case' | 'car-stop' | 'cfs') => {
    if (type === 'case') {
      setIsFullSidebar(true);
      setSelectedTab('case');
    } else {
      showLoader();
      setSelectedTab('');
      setIsFullSidebar(true);
      CfsApi.create()
        .then((response) => {
          setActiveCfsId(response.data._id as string);
          if (type === 'cfs') {
            setSelectedTab('incident-cfs');
          } else {
            setSelectedTab('car-stop-cfs');
          }
        })
        .catch((error) => {
          updateToast({
            open: true,
            message: error ? error.message : 'Error creating CFS',
          });
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  const handleSubmit = () => {};

  const classes = useStyles();

  return (
    <Page title="First Responders Dashboard" style={{ paddingTop: 0 }}>
      <Grid container>
        <Grid xs={12} item>
          <FireHydrantMap
            center={mapCenter || defaultMapCenter}
            defaultZoom={12}
            markers={activeCfsMarkers
              .filter((marker) => !cfsId || marker.key === cfsId)
              .concat(isRoadAssistace ? unitShiftMarkers : [])}
            showContextMenu={false}
            showStreetViewControl={false}
            mapTypeControl={false}
            showZoomControl={false}
            style={{ height: '100vh' }}
          >
            <MapControl position={ControlPosition.RIGHT_TOP}>
              <Box
                className={
                  isFullSidebar ? classes.sidebarFull : classes.sidebar
                }
                sx={{
                  transform: isSidebarOpen
                    ? 'translateX(0)'
                    : 'translateX(96%)',
                  width: isFullSidebar
                    ? sidebarContentWidth
                    : sidebarContentWidth / 2,
                }}
              >
                <IconButton
                  className={classes.toggleButton}
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  {!isSidebarOpen ? <IcArrowLeft /> : <IcArrowRight />}
                </IconButton>
                <Stack className={classes.contollButtons}>
                  <IconButton
                    className={`${classes.controllButton} ${classes.topButtonGroup}`}
                    onClick={() => setRoadAssistanceStatus(true)}
                  >
                    <IcRoadAssistanceOn
                      width={24}
                      height={24}
                      style={
                        isRoadAssistace ? { opacity: 1 } : { opacity: 0.3 }
                      }
                    />
                  </IconButton>
                  <IconButton
                    className={`${classes.controllButton} ${classes.bottomButtonGroup}`}
                    onClick={() => setRoadAssistanceStatus(false)}
                  >
                    <IcRoadAssistanceOff
                      width={24}
                      height={24}
                      style={
                        !isRoadAssistace ? { opacity: 1 } : { opacity: 0.3 }
                      }
                    />
                  </IconButton>
                  <IconButton
                    className={`${classes.controllButton} ${classes.locate}`}
                    onClick={locateMe}
                  >
                    <IcLocationSelect width={24} height={24} />
                  </IconButton>
                  <IconButton
                    className={`${classes.controllButton} ${classes.topButtonGroup}`}
                    onClick={() => updateZoom(true)}
                  >
                    <IcPlus width={24} height={24} />
                  </IconButton>
                  <IconButton
                    className={`${classes.controllButton} ${classes.bottomButtonGroup}`}
                    onClick={() => updateZoom(false)}
                  >
                    <IcMinus width={24} height={24} />
                  </IconButton>
                </Stack>

                <Box className={classes.sidebarContent}>
                  {!isFullSidebar && (
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingRight={2}
                    >
                      <FirstResponderTabs
                        onTabChange={handleTabChange}
                        page="map-view"
                      />
                      <DashboardActions
                        onCreateCarStop={() => {
                          handleCreateCfs('car-stop');
                        }}
                        onCreateIncident={() => {
                          handleCreateCfs('case');
                        }}
                        onCreateCFS={() => {
                          handleCreateCfs('cfs');
                        }}
                      />
                    </Stack>
                  )}

                  <MasterNamesContextProvider
                    nextRoutes={['']}
                    handleSubmit={handleSubmit}
                    involvementOptions={CFSMasterNameInvolvement}
                    updateMasterName={(nameId: string, data) => {
                      return MasterNameApi.updateMasterName({
                        _id: nameId,
                        ...data,
                      });
                    }}
                    addLinkedName={(
                      nameId: string,
                      data?: {
                        relationship: ICFSInvolvement;
                      },
                    ) =>
                      CfsApi.addCfsMasterName(
                        String(cfs?._id),
                        nameId,
                        data as { relationship: ICFSInvolvement },
                      )
                    }
                    updateLinkedName={(
                      nameId: string,
                      data: {
                        relationship: ICFSInvolvement;
                      },
                    ) =>
                      CfsApi.updateCfsMasterName(
                        String(cfs?._id),
                        nameId,
                        data as { relationship: ICFSInvolvement },
                      )
                    }
                    saveNote={(
                      nameId: string,
                      description: string,
                      noteId?: string,
                    ) => {
                      return noteId
                        ? MasterNameApi.updateMasterNameNote(
                            nameId,
                            noteId,
                            description,
                          )
                        : CfsApi.createMasterNameNote(
                            String(cfs?._id),
                            nameId,
                            description,
                          );
                    }}
                    deleteLinkedName={(nameId: string) =>
                      CfsApi.deleteCfsMasterName(String(cfs?._id), nameId)
                    }
                    getLinkedMasterName={(nameId: string) =>
                      CfsApi.getCfsMasterName(String(cfs?._id), nameId)
                    }
                    getLinkedMasterNames={() =>
                      CfsApi.getCfsMasterNames(String(cfs?._id))
                    }
                    getMasterName={(nameId: string) =>
                      MasterNameApi.getMasterName(nameId)
                    }
                  >
                    <MasterVehicleContextProvider
                      nextRoutes={['']}
                      getLinkedVehicles={() =>
                        CfsApi.getCfsMasterVehicles(String(cfs?._id))
                      }
                      addLinkedVehicles={(vehicleId: string) =>
                        CfsApi.addCfsMasterVehicle(String(cfs?._id), vehicleId)
                      }
                      deleteLinkedVehicle={(vehicleId: string) =>
                        CfsApi.deleteCfsMasterVehicle(
                          String(cfs?._id),
                          vehicleId,
                        )
                      }
                    >
                      <Stack
                        height="100%"
                        display="flex"
                        flex={1}
                        overflow="auto"
                      >
                        {renderTabContent()}
                      </Stack>
                    </MasterVehicleContextProvider>
                  </MasterNamesContextProvider>
                </Box>
              </Box>
            </MapControl>
          </FireHydrantMap>
        </Grid>
      </Grid>
      {/* MODALS STARTS HERE */}
      <FirstResponderModal
        open={modalState.cfsDetails}
        onClose={() => toggleModal('cfsDetails')}
      >
        <CfsDetailsModalContent
          toggleModal={toggleModal}
          cfs={activeCfs}
          action={() => {
            if (activeCfs) {
              handleSlefAssignCfs(activeCfs);
            }
          }}
        />
      </FirstResponderModal>

      <FirstResponderModal
        open={modalState.backupRequested || false}
        onClose={() => toggleModal('backupRequested')}
      >
        {backupRequestedCFS ? (
          <BackupRequestedModalContent
            toggleModal={toggleModal}
            cfsId={backupRequestedCFS?.cfsNumber || ''}
            action={answerBackupRequest}
          />
        ) : (
          <></>
        )}
      </FirstResponderModal>
      <FirstResponderModal
        open={modalState.newCfsAssigned}
        onClose={() => toggleModal('newCfsAssigned')}
        title="NEW CFS ASSIGNED"
      >
        <NewCfsAssignedModalContent
          cfsId={assignedCfsId as string}
          action={() => {
            navigate('/my-cfs');
          }}
          toggleModal={toggleModal}
        />
      </FirstResponderModal>
      <FirstResponderModal
        open={modalState.backupRequestRejected}
        onClose={() => toggleModal('backupRequestRejected')}
      >
        <BackupRequestRejectedModalContent
          toggleModal={toggleModal}
          unitName={rejectedUnitName}
        />
      </FirstResponderModal>

      {/* MODALS ENDS HERE */}
    </Page>
  );
};

export default withLoader(CFSFirstResponders);
