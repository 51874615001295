import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import {
  IAlcoholBrand,
  IEntry,
  IKaseLog,
  IProperty,
  KaseEvent,
  IPropertyRequest,
} from '@/models';

import { http } from './http';
import { IGetParams, IListResponse, IPaginatedListResponse } from './types';

export type ICustodyCheckInReq = {
  inventoryLocation: string;
  notes: string;
};

export type ICustodyCheckoutReq = {
  expectedBackDate?: string;
  notes: string;
  vendor: string;
  signature: string;
  trackingNumber: string;
};

export type ICustodyCheckoutPersonReq = {
  user: string;
  phoneNumber?: string;
  signature: string;
  expectedBackDate?: string;
  notes: string;
};

export type ICustodyTransferReq = {
  agency: {
    name: string;
    phoneNumber: string;
    contact: {
      name: string;
      phoneNumber: string;
    };
  };
  user: {
    name: string;
    badgeNumber: string;
  };
  expectedBackDate?: string;
  signature: string;
  notes: string;
};

export const PROPERTY_CUSTODY_EVENTS: KaseEvent[] = [
  KaseEvent.LOGGED_PROPERTY,
  KaseEvent.SEIZED_PROPERTY_IN_POLICE_CUSTODY,
  KaseEvent.RELEASED_PROPERTY_TO_LAB,
  KaseEvent.RELEASED_PROPERTY_TO_COURT,
  KaseEvent.RELEASED_PROPERTY_TO_PERSON,
  KaseEvent.UPDATED_PROPERTY_LOCATION,
  KaseEvent.RELEASED_PROPERTY_TO_OWNER,
  KaseEvent.DISPOSED_PROPERTY,
  KaseEvent.DESTROYED_PROPERTY,
  KaseEvent.AUCTIONED_PROPERTY,
  KaseEvent.TRANSFERRED_PROPERTY,
];

export const KasePropertyApi = {
  kaseId: '',
  list: (
    kaseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IProperty[]>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/properties`, {
      params,
    });
  },
  paginatedList: (
    kaseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<IProperty>>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/properties/list`, {
      params,
    });
  },
  getOne: async (
    kaseId: string,
    id: string,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/properties/${id}`);
  },
  save: async (
    kaseId: string,
    data: IPropertyRequest,
  ): Promise<AxiosResponse<IProperty>> => {
    return data._id
      ? KasePropertyApi.update(kaseId, data._id, data)
      : KasePropertyApi.create(kaseId, data);
  },
  create: async (
    kaseId: string,
    data: IPropertyRequest,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.post(`api/${ApiVersion.V1}/kases/${kaseId}/properties`, data);
  },
  update: async (
    kaseId: string,
    id: string,
    data: IPropertyRequest,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.put(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/${id}`,
      data,
    );
  },
  delete: async (
    kaseId: string,
    propertyId: string,
  ): Promise<AxiosResponse> => {
    return http.delete(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/${propertyId}`,
    );
  },
  getAlcoholBrands: (): Promise<
    AxiosResponse<IListResponse<IAlcoholBrand>>
  > => {
    return http.get(`api/${ApiVersion.V1}/alcohol-brands`);
  },
  createAlcoholBrand: (data: IEntry): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/alcohol-brands`, data);
  },
  getFirearmMakes: (): Promise<AxiosResponse<IListResponse<IEntry>>> => {
    return http.get(`api/${ApiVersion.V1}/firearms/makes`);
  },
  createFirearmMake: (data: IEntry): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/firearms/makes`, data);
  },
  getFirearmModels: (makeId: string): Promise<AxiosResponse<IEntry[]>> => {
    return http.get(`api/${ApiVersion.V1}/firearms/makes/${makeId}/models`);
  },
  addFirearmModel: (makeId: string, name: string): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/firearms/makes/${makeId}/models`, {
      name,
    });
  },

  getKnifeMakes: (): Promise<AxiosResponse<IListResponse<IEntry>>> => {
    return http.get(`api/${ApiVersion.V1}/knives/makes`);
  },
  createKnifeMake: (data: IEntry): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/knives/makes`, data);
  },
  getKnifeModels: (makeId: string): Promise<AxiosResponse<IEntry[]>> => {
    return http.get(`api/${ApiVersion.V1}/knives/makes/${makeId}/models`);
  },
  addKnifeModel: (makeId: string, name: string): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/knives/makes/${makeId}/models`, {
      name,
    });
  },
  getJewelryTypes: (): Promise<AxiosResponse<IListResponse<IEntry>>> => {
    return http.get(`api/${ApiVersion.V1}/jewelry-types`);
  },
  createJewelryType: (data: IEntry): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/jewelry-types`, data);
  },

  // Custody action apis of case property
  custodyCheckIn: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyCheckInReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/checkin`,
      { ...data, properties: propertyId },
    );
  },
  custodyCheckoutLab: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyCheckoutReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/checkout-to-lab`,
      { ...data, properties: propertyId },
    );
  },
  custodyCheckoutCourt: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyCheckoutReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/checkout-to-court`,
      { ...data, properties: propertyId },
    );
  },
  custodyCheckoutPerson: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyCheckoutPersonReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/checkout-to-person`,
      { ...data, properties: propertyId },
    );
  },
  custodyDispose: (
    kaseId: string,
    propertyId: string[],
    notes: string,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/dispose`,
      { notes, properties: propertyId },
    );
  },
  custodyMoveLocation: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyCheckInReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/move-location`,
      { ...data, properties: propertyId },
    );
  },
  custodyReleaseToOwner: (
    kaseId: string,
    propertyId: string[],
    notes: string,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/release-to-owner`,
      { notes, properties: propertyId },
    );
  },
  custodyDestroy: (
    kaseId: string,
    propertyId: string[],
    notes: string,
  ): Promise<AxiosResponse<IProperty>> => {
    // ${propertyId}/
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/destroy`,
      { notes, properties: propertyId },
    );
  },
  custodyAuction: (
    kaseId: string,
    propertyId: string[],
    notes: string,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/auction`,
      { notes, properties: propertyId },
    );
  },
  custodyTransfer: (
    kaseId: string,
    propertyId: string[],
    data: ICustodyTransferReq,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.patch(
      `api/${ApiVersion.V1}/kases/${kaseId}/properties/transfer`,
      { ...data, properties: propertyId },
    );
  },
  getCustodyLogs: (
    kaseId: string,
    propertyId?: string,
  ): Promise<AxiosResponse<IListResponse<IKaseLog>>> => {
    const filter = JSON.stringify({
      event: {
        $in: PROPERTY_CUSTODY_EVENTS,
      },
      'linkedItems.property': propertyId,
    });
    return http.get(`api/${ApiVersion.V1}/kases/${kaseId}/logs`, {
      params: { filter, limit: 1000 },
    });
  },
};
