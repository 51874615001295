import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const FilePickerRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 275,
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '& .pickerBox': {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    border: `1px dashed ${colors.grey['20']}`,
    cursor: 'pointer',
    borderRadius: 8,
    background: theme.palette.background.default,

    '&.pickerBox--prohibited': {
      opacity: 0.7,
      pointerEvents: 'none',
    },
  },

  '&.vertical-aligned-file-picker': {
    display: 'block',
    height: '100%',
    padding: 0,

    '& .pickerBox': {
      marginRight: 0,
      height: 180,
      marginBottom: '15px',
      cursor: 'pointer',

      '& .title1, .title2': {
        fontWeight: 'bold',
      },
      '& svg': {
        width: 36,
        height: 36,
        marginBottom: '14px',
      },
    },
  },
}));

export const FileListRoot = styled(Stack)(() => ({
  flex: 1,
  height: '100%',
  justifyContent: 'flex-start',
  overflow: 'auto',

  '&.vertical': {
    height: 'unset',
    maxHeight: 350,
    overflow: 'auto',
  },
}));

export const FileListItemRoot = styled(Stack)(() => ({
  width: '100%',
  minHeight: 80,
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 10,
  border: `1px solid ${colors.grey['20']}`,
  borderRadius: 8,
  overflow: 'hidden',

  '& .icon_box': {
    display: 'flex',
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${colors.grey['20']}`,
    background: colors.grey[10],
    cursor: 'pointer',
  },

  '& .image_preview': {
    width: 80,
    height: 80,
    objectFit: 'cover',
    cursor: 'pointer',
  },

  '& .content_box': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    overflow: 'hidden',

    '& > div': {
      flex: 1,
      minWidth: 0,
      marginRight: 15,
    },

    '& .txt_name': {
      display: 'block',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.black,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& .txt_size': {
      fontSize: '0.75rem',
      color: colors.grey[80],
    },

    '& .txt_error': {
      fontSize: '0.75rem',
      color: colors.error.main,
    },

    '& .icon_remove': {
      color: colors.error.main,
      cursor: 'pointer',
    },

    '&.edit-description': {
      '& .txt_name ': {
        width: 200,
        maxWidth: 283,
        color: colors.grey[100],
        lineHeight: '20px',
        marginRight: '15px',
      },
      '& .txt_size': {
        lineHeight: '20px',
      },
      '& .icon_remove': {
        width: 28,
        height: 28,
      },
    },
  },
}));
