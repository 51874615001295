import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const componentCustomStyles = {
  layout: {
    sx: {
      ['.MuiPickersLayout-actionBar .MuiButtonBase-root']: {
        color: colors.error.main,
      },
    },
  },
};

export const DateTimePickerRoot = styled(Stack)(() => ({
  flex: 1,
  width: '100%',
  maxWidth: '100%',
  marginBottom: 8,

  '& .MuiFormControl-root': {
    width: '100%',
  },

  '&.hide-input': {
    '& .MuiInputBase-root': {
      padding: 0,
      border: 0,

      '& input': {
        width: 0,
      },
    },
  },

  '& .MuiInputBase-root': {
    '& svg': {
      marginBottom: -3,
      cursor: 'pointer',
    },
  },
}));
