import { colors } from '../variables';

export default {
  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiTab-root': {
          textTransform: 'capitalize',
        },

        '.MuiButtonBase-root': {
          padding: 0,
          marginRight: '20px',
        },
        '.MuiTabs-indicator': {
          backgroundColor: colors.error.main,
        },
      },
    },
  },
};
