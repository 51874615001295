import {
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { VehicleApi } from '@/api';
import { IcArrowDown } from '@/assets/images';
import { AutoCompleteRoot } from '@/components/FormElements/Autocomplete/styles';
import { IVehicleMake } from '@/models';
import { useToastStore } from '@/store';

interface IVehicleMakeAutoCompleteProps {
  excludeVehicleMakes?: Array<IVehicleMake>;
  noResults?: ReactNode;
  disabled?: boolean;
  label: string;
  value: IVehicleMake | string;
  onChange: (value: IVehicleMake) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  sx: any;
}

export const VehicleMakeAutocomplete = ({
  excludeVehicleMakes = [],
  noResults,
  onChange,
  value,
  label,
  onKeyUp,
  sx,
  disabled = false,
  ...restProps
}: IVehicleMakeAutoCompleteProps) => {
  const [searchText, setSearchText] = useState('');
  const [vehicleMakes, setVehicleMakes] = useState<Array<IVehicleMake>>([]);

  const { updateToast } = useToastStore();

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setSearchText(value);
      } else {
        setSearchText(value.name);
      }
    }
  }, [value]);

  useEffect(() => {
    fetchVehicleMakes();
  }, []);

  const fetchVehicleMakes = async (search?: string) => {
    try {
      const res = await VehicleApi.getVehicleMakeOptions({
        search,
      });
      setVehicleMakes(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err });
    }
  };

  const debouncedFetchVehicleMakes = useDebouncedCallback((search: string) => {
    fetchVehicleMakes(search);
  }, 500);

  const handleInputChange = (_: SyntheticEvent, v: string) => {
    setSearchText(v);
    debouncedFetchVehicleMakes(v);
  };

  return (
    <AutoCompleteRoot sx={sx} {...restProps}>
      {!!label && <InputLabel>{label}</InputLabel>}

      <Autocomplete
        inputValue={searchText}
        autoHighlight
        popupIcon={<IcArrowDown />}
        fullWidth
        disabled={disabled}
        onInputChange={handleInputChange}
        options={vehicleMakes}
        noOptionsText={noResults}
        filterOptions={(allVehicleMakes) => {
          return allVehicleMakes.reduce((acc, item) => {
            const found = (excludeVehicleMakes || []).find((excludedItem) => {
              return excludedItem?._id === item._id;
            });
            return found ? acc : [...acc, item];
          }, [] as Array<IVehicleMake>);
        }}
        onChange={(_, v) => {
          if (v) onChange(v);
        }}
        getOptionLabel={(o) => {
          return o.name;
        }}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option._id}
              flexDirection="column"
              flexWrap="wrap"
              sx={{
                m: 1,
              }}
            >
              <p style={{ width: '100%', margin: 0 }}>{option.name}</p>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={searchText}
            placeholder="Make"
            onKeyUp={(e) => {
              if (onKeyUp) {
                onKeyUp(e);
              }
            }}
            fullWidth
          />
        )}
        clearOnBlur={false}
        clearIcon={<ClearIcon sx={{ width: 20, height: 20 }} />}
        disableClearable={false}
      />
    </AutoCompleteRoot>
  );
};
