import { Divider, Typography, styled } from '@mui/material';

import { IUnitShiftResource } from '@/models';
import { colors } from '@/theme/variables';

const UnitShiftMarkerInfoItemRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,

  '& .header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },

  '& .txt-unit-name': {
    fontSize: '0.875rem',
    color: colors.black[100],
    fontWeight: 500,
  },

  '& .cfs-number': {
    borderRadius: 6,
    padding: '2px 8px',
    marginLeft: 8,
    backgroundColor: '#E7EAEC',
  },

  '& .txt-cfs-number': {
    color: colors.black[80],
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '18px',
  },

  '& .txt-description': {
    color: colors.black[80],
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}));

interface UnitShiftMarkerInfoItemProps {
  unitName: string;
  unitEmployees?: IUnitShiftResource[];
  cfsNumber?: string;
  cfsDescription?: string;
}

export const UnitShiftMarkerInfoItem = ({
  unitName,
  unitEmployees,
  cfsNumber,
  cfsDescription,
}: UnitShiftMarkerInfoItemProps) => {
  return (
    <UnitShiftMarkerInfoItemRoot>
      <div className="header">
        <Typography className="txt-unit-name">{unitName}</Typography>
        {!!cfsNumber && (
          <div className="cfs-number">
            <Typography className="txt-cfs-number">{cfsNumber}</Typography>
          </div>
        )}
      </div>
      {!!unitEmployees &&
        unitEmployees.map((employee) => (
          <>
            <Typography className="txt-description">
              Name: {employee.firstName} {employee.lastName}
            </Typography>

            <Typography className="txt-description">
              Badge #:{' '}
              {employee.profile?.employmentInformation?.badgeNumber || 'N/A'}
            </Typography>
            <Divider />
          </>
        ))}
      {!!cfsDescription && (
        <Typography className="txt-description">{cfsDescription}</Typography>
      )}
    </UnitShiftMarkerInfoItemRoot>
  );
};
