import { Typography, styled } from '@mui/material';

import { colors } from '@/theme/variables';

const UnitShiftMarkerInfoItemRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,

  '& .header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },

  '& .txt-name': {
    fontSize: '0.875rem',
    color: colors.black[100],
    fontWeight: 500,
  },

  '& .badge-number': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  '& .txt-badge-number': {
    color: colors.black[40],
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '18px',
  },
}));

interface UserMarkerInfoItemProps {
  userName: string;
  badgeNumber?: string;
}

export const UserMarkerInfoItem = ({
  userName,
  badgeNumber,
}: UserMarkerInfoItemProps) => {
  return (
    <UnitShiftMarkerInfoItemRoot>
      <div className="header">
        <Typography className="txt-name">{userName}</Typography>
      </div>
      {!!badgeNumber && (
        <div className="badge-number">
          <Typography className="txt-badge-number">Badge: &nbsp;</Typography>
          <Typography className="txt-badge-number">{badgeNumber}</Typography>
        </div>
      )}
    </UnitShiftMarkerInfoItemRoot>
  );
};
