import { FC } from 'react';

import { Box, Stack, useTheme } from '@mui/material';
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps';

import { SearchBar } from '@/components';
import { useUnitShiftContext } from '@/hooks';
import { FilterMenu } from '@/pages/CFS/ActiveCFSList/components/FilterMenu';
interface SearchFilterControlProps {
  position?: ControlPosition;
}

export const SearchFilterControl: FC<SearchFilterControlProps> = ({
  position = ControlPosition.TOP_RIGHT,
}) => {
  const { setCfsFilterState, cfsFilterState } = useUnitShiftContext();

  const handleSearch = (value: string) => {
    setCfsFilterState({ searchText: value });
  };

  const handleFilterChange = (filter: any) => {
    setCfsFilterState(filter);
  };
  const theme = useTheme();

  return (
    <MapControl position={position}>
      <Box
        sx={{
          mt: 2,
          mr: 2,
          p: 1,
          backgroundColor: theme.palette.background.default,
          boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
          border: '1px solid #ccc',
          borderRadius: '2px',
        }}
      >
        <Stack direction="row" spacing={1}>
          <SearchBar
            onChange={handleSearch}
            placeholder="Search CFS..."
            value={cfsFilterState.searchText}
            sx={{
              marginRight: '0',
              '& .MuiInputBase-root': {
                backgroundColor: 'white',
              },
            }}
          />
          <FilterMenu onChangeFilter={handleFilterChange} />
        </Stack>
      </Box>
    </MapControl>
  );
};
