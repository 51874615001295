import { IconButton, Typography } from '@mui/material';
import moment from 'moment';

import { IcInfoCircle } from '@/assets/images';
import rapidSOSImage from '@/assets/images/pngs/rapidsos.png';
import { DATE_FORMAT, TIME_FORMAT } from '@/config';
import { colors } from '@/theme/variables';

interface CallerLocationInfoProps {
  timestamp: number;
  location: string;
  onInfoClick: () => void;
}

export const CallerLocationInfo = ({
  timestamp,
  onInfoClick,
  location,
}: CallerLocationInfoProps) => {
  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: '15px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          justifyItems: 'flex-start',
          justifyContent: 'flex-start',
          columnGap: '5px',
        }}
      >
        <img style={{ height: 28, width: 28 }} src={rapidSOSImage} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            rowGap: 0,
          }}
        >
          <div>
            <Typography color={colors.grey[100]} fontWeight={500} fontSize={14}>
              Caller Location
            </Typography>
          </div>

          <div>
            <Typography color={colors.grey[70]} fontSize={12} fontWeight={700}>
              {location}
            </Typography>
          </div>

          <div style={{ display: 'flex' }}>
            <Typography color={colors.grey[70]} fontWeight={400} fontSize={12}>
              {moment(timestamp).format(TIME_FORMAT)}
            </Typography>

            <Typography
              fontWeight={900}
              color={colors.error.main}
              style={{ padding: '0px 4px' }}
            >
              ·
            </Typography>

            <Typography color={colors.grey[70]} fontWeight={400} fontSize={12}>
              {moment(timestamp).format(DATE_FORMAT)}
            </Typography>
          </div>
        </div>

        <IconButton
          onClick={() => {
            onInfoClick();
          }}
        >
          <IcInfoCircle
            style={{ width: 15, height: 15, color: colors.primary.main }}
          />
        </IconButton>
      </div>
    </>
  );
};
