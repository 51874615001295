export const DefaultEditorConfig = {
  mention: {
    separator: ' ',
    trigger: '@',
    suggestions: [],
  },
  toolbar: {
    options: ['inline', 'blockType', 'list', 'textAlign'],
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: [
        'Arial',
        'Georgia',
        'Impact',
        'Tahoma',
        'Times New Roman',
        'Verdana',
      ],
    },
  },
};
