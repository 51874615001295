import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import {
  IUser,
  IEquipment,
  ILineLayer,
  IPointLayer,
  IPolygonLayer,
  IUnit,
  IUnitForm,
  IUnitType,
  IVehicle,
  UnitStatus,
  IUnitShift,
  IUnitResourceForm,
  UnitShiftStatus,
  IUnitShiftForm,
  IUnitFilter,
  IUnitFilterForm,
  IUnitShiftAssignment,
} from '@/models';

import { http } from './http';
import {
  ICustomPaginateResponse,
  IGetParams,
  IListResponse,
  IPaginatedListResponse,
} from './types';

export interface AvailableResources {
  vehicles?: IVehicle[];
  users?: IUser[];
  equipments?: IEquipment[];
  polygons?: IPolygonLayer[];
  lines?: ILineLayer[];
  points?: IPointLayer[];
}

export const UnitApi = {
  //Unit type apis
  getUnitType: (id: string): Promise<AxiosResponse<IUnitType>> => {
    return http.get(`api/${ApiVersion.V1}/units/types/${id}`);
  },
  getUnitTypes: (): Promise<AxiosResponse<IUnitType[]>> => {
    return http.get(`api/${ApiVersion.V1}/units/types/`);
  },
  getAvailableResources: (
    type?: string,
    unitId?: string,
  ): Promise<AxiosResponse<AvailableResources>> => {
    return http.get(`api/${ApiVersion.V1}/units/units/available-resources`, {
      params: { type, unitId },
    });
  },

  // Unit apis
  getUnits: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IUnit>>> => {
    return http.get(`api/${ApiVersion.V1}/units/units`, { params });
  },
  getPaginatedUnits: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<IUnit>>> => {
    return http.get(`api/${ApiVersion.V1}/units/units/list`, { params });
  },
  getUnit: (id: string): Promise<AxiosResponse<IUnit>> => {
    return http.get(`api/${ApiVersion.V1}/units/units/${id}`);
  },
  createUnit: (data: IUnitForm): Promise<AxiosResponse<IUnit>> => {
    return http.post(`api/${ApiVersion.V1}/units/units`, data);
  },
  updateUnit: async ({
    _id,
    ...data
  }: IUnitForm): Promise<AxiosResponse<IUnit>> => {
    return http.put(`api/${ApiVersion.V1}/units/units/${_id}`, data);
  },
  updateUnitStatus: async (
    id: string,
    status: UnitStatus,
  ): Promise<AxiosResponse<IUnit>> => {
    return http.patch(`api/${ApiVersion.V1}/units/units/${id}/status`, {
      status,
    });
  },
  setUnitOffDuty: async (id: string): Promise<AxiosResponse<IUnit>> => {
    return http.patch(`api/${ApiVersion.V1}/units/units/${id}/off-duty`);
  },
  deleteUnit: (id: string): Promise<AxiosResponse<IUnit>> => {
    return http.delete(`api/${ApiVersion.V1}/units/units/${id}`);
  },

  // Unit shifts apis
  getUnitShifts: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IUnitShift>>> => {
    return http.get(`api/${ApiVersion.V1}/units/shifts`, { params });
  },
  getUnitShiftsList: (
    params?: IGetParams,
  ): Promise<AxiosResponse<ICustomPaginateResponse<IUnitShiftAssignment>>> => {
    return http.get(`api/${ApiVersion.V1}/units/shifts/list`, { params });
  },
  getUnitShift: (id: string): Promise<AxiosResponse<IUnitShift>> => {
    return http.get(`api/${ApiVersion.V1}/units/shifts/${id}`);
  },
  createUnitShift: (
    data: IUnitShiftForm,
  ): Promise<AxiosResponse<IUnitShift>> => {
    return http.post(`api/${ApiVersion.V1}/units/shifts`, data);
  },
  updateUnitShifts: async (
    _id: string,
    data: IUnitResourceForm,
  ): Promise<AxiosResponse<IUnit>> => {
    return http.put(`api/${ApiVersion.V1}/units/shifts/${_id}`, data);
  },
  updateUnitShiftStatus: async (
    _id: string,
    status: UnitShiftStatus,
  ): Promise<AxiosResponse<IUnit>> => {
    return http.patch(`api/${ApiVersion.V1}/units/shifts/${_id}`, { status });
  },

  // Unit filters
  getUnitFilters: (): Promise<AxiosResponse<IUnitFilter[]>> => {
    return http.get(`api/${ApiVersion.V1}/units/filters`);
  },
  createUnitFilter: (
    data: IUnitFilterForm,
  ): Promise<AxiosResponse<IUnitFilter>> => {
    return http.post(`api/${ApiVersion.V1}/units/filters`, data);
  },
  updateUnitFilter: ({
    _id,
    ...rest
  }: IUnitFilterForm): Promise<AxiosResponse<IUnitFilter>> => {
    return http.put(`api/${ApiVersion.V1}/units/filters/${_id}`, rest);
  },
  deleteUnitFilter: (id: string): Promise<AxiosResponse<IUnitFilter>> => {
    return http.delete(`api/${ApiVersion.V1}/units/filters/${id}`);
  },
};
