import { ReactNode } from 'react';

import { AnimationWrapper } from '@/components';

import { useTabLayoutContext } from '..';

interface TabAnimationWrapperProps {
  children: ReactNode;
  nextPaths?: string[];
}

export const TabAnimationWrapper = (props: TabAnimationWrapperProps) => {
  const { nextPaths, children } = props;
  const { nextPath } = useTabLayoutContext();

  let initialX = '100%';
  let animateX = '0';
  let exit = '-100%';
  if (nextPaths && nextPath && nextPaths?.includes(nextPath)) {
    initialX = '-100%';
    animateX = '0';
    exit = '100%';
  }

  return (
    <AnimationWrapper
      initial={{ x: initialX }}
      animate={{ x: animateX }}
      exit={{ x: exit, opacity: 0 }}
      transition={{ ease: 'easeIn', duration: 0.2 }}
    >
      {children}
    </AnimationWrapper>
  );
};
