import { Loader } from '@googlemaps/js-api-loader';

import { googleMapsApiKey } from '@/config';
import { IAddressForm, PlaceType, PointType } from '@/models';

const AddressPropertyMap: Record<string, string> = {
  intersection: 'intersection',
  addressNumber: 'street_number',
  completeStreetName: 'route',
  zipcode: 'postal_code',
  zipName: 'locality',
  state: 'administrative_area_level_1',
  country: 'country',
  placeName: 'locality',
};

export type Prediction = {
  description: string;
  place_id: string;
};

export type PlaceDetail = {
  // fields here depend on the fields param passed to getDetails
  formatted_address?: string;
  geometry?: {
    location: { lat: () => number; lng: () => number };
  };
  name?: string;
  place_id?: string;
};

class GooglePlaceService {
  googleMapClient: google.maps.PlacesLibrary | undefined;

  sessionToken: google.maps.places.AutocompleteSessionToken | undefined;

  async getGoogleMapsClient(): Promise<google.maps.PlacesLibrary> {
    if (this.googleMapClient) {
      return this.googleMapClient;
    }
    const loader = new Loader({
      apiKey: googleMapsApiKey || '',
      version: 'weekly',
      libraries: ['places'],
    });
    this.googleMapClient = await loader.importLibrary('places');
    return this.googleMapClient;
  }

  async getPlacePredictions(
    search: string,
    locationRestriction?: google.maps.LatLngBoundsLiteral,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    if (!search || search.trim().length < 1) {
      return [];
    }

    await this.getGoogleMapsClient();

    if (!this.sessionToken) {
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }

    try {
      const predictionsRes =
        await new google.maps.places.AutocompleteService().getPlacePredictions({
          input: search,
          componentRestrictions: { country: 'us' },
          sessionToken: this.sessionToken,
          locationRestriction,
        });
      return predictionsRes.predictions || [];
    } catch (err) {
      return [];
    }
  }

  async getPlaceDetails(place_id: string): Promise<IAddressForm | null> {
    await this.getGoogleMapsClient();
    return new Promise((resolve, reject) => {
      new google.maps.places.PlacesService(
        document.createElement('div'),
      ).getDetails(
        {
          placeId: place_id,
          fields: [
            // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
            'address_components',
            'formatted_address',
            'name',
            'place_id',
            'geometry.location',
          ],
          sessionToken: this.sessionToken,
        },
        (place: any, status: any) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            return resolve(this.getAddressDetails(place));
          }
          return reject(null);
        },
      );
    });
  }

  /**
   * Fetches the first Google geocoder response using the given coordinates.
   * @param lat
   * @param lng
   * @returns
   */
  geocodeCoordinates(
    lat: number,
    lng: number,
  ): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      new google.maps.Geocoder().geocode(
        {
          location: {
            lat,
            lng,
          },
        },
        (places: google.maps.GeocoderResult[] | null) => {
          if (places?.length && places.length > 0) {
            return resolve(places[0]);
          }
          return reject(null);
        },
      );
    });
  }

  getAddressDetails(placeDetails: google.maps.places.PlaceResult) {
    const addressComponents = placeDetails?.address_components;
    if (placeDetails && addressComponents) {
      const coordinates = placeDetails.geometry?.location;
      const addressDetails = Object.keys(AddressPropertyMap).reduce(
        (items: Record<string, any>, currentKey) => {
          let itemValue = addressComponents.find(
            ({ types }) => types.indexOf(AddressPropertyMap[currentKey]) > -1,
          );
          if (!itemValue && currentKey === 'zipName') {
            itemValue = addressComponents.find(
              ({ types }) =>
                types.indexOf('sublocality') > -1 ||
                types.indexOf('administrative_area_level_2') > -1,
            );
          }
          if (currentKey === 'completeStreetName') {
            items.completeStreetName = itemValue?.long_name ?? '';
            items.shortStreetName = itemValue?.short_name ?? '';
          } else if (currentKey === 'intersection' && itemValue) {
            items.intersectionStreets = itemValue?.long_name.split('&');
            items.placeType = PlaceType.INTERSECTION;
          } else {
            items[currentKey] = itemValue?.short_name ?? '';
          }
          return items;
        },
        {},
      );
      if (!addressDetails.placeType) {
        addressDetails.addressLabel = `${addressDetails.addressNumber} ${addressDetails.shortStreetName}`;
      }
      addressDetails.pointType = PointType.MISCELLANEOUS;
      addressDetails.point = {
        type: 'Point',
        coordinates: [coordinates?.lng() || 0, coordinates?.lat() || 0],
      };
      delete addressDetails.shortStreetName;
      return addressDetails;
    }
    return null;
  }
}

export const googlePlaceService = new GooglePlaceService();
