import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi } from '@/api';
import { AssignedUnitShiftItem } from '@/contexts';
import { withLoader } from '@/hocs';
import { useCFSContext, useUnitListFilter, useUnitShiftContext } from '@/hooks';
import { AssignedUnitShiftStatus } from '@/models';
import { getUnitShiftAddressLabel } from '@/services';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { AssignedUnitItem } from './AssignedUnitItem';
import { UnitAccordion } from './UnitAccordion';
import { UnitItem } from './UnitItem';
import { CommonUnitsProps } from './UnitShiftList';
import {
  unitShiftActionItems,
  unitShiftCallActionItems,
  unitShiftHQActionItems,
} from '../data';

const AssignedUnits: FC<CommonUnitsProps> = ({
  cfsId,
  filterValues,
  searchText,
  showLoader,
  hideLoader,
  handleAssignUnit,
}) => {
  const [queryParams] = useSearchParams();
  const { updateToast } = useToastStore();
  const { cfs, unitShiftDistances, fetchCFS } = useCFSContext();
  const { assignedUnitShifts, unitShiftAddresses, fetchAssignedUnitShifts } =
    useUnitShiftContext();
  const { account } = useUserStore();
  const { channel } = useChannel(`account:${account?._id}:cfs:${cfsId}`);

  const [expanded, setExpanded] = useState<string | null>(null);
  const agencyId = queryParams.get('agencyId');

  const { currentCallUnitShifts, otherCallUnitShifts, canceledAndOpenShifts } =
    useMemo(() => {
      const otherUnitShifts: AssignedUnitShiftItem[] = [];
      const currentUnitShifts: AssignedUnitShiftItem[] = [];
      const canceledAndOpenUnitShifts: AssignedUnitShiftItem[] = [];

      assignedUnitShifts.forEach((assignedUnitShift) => {
        if (cfsId && cfsId === assignedUnitShift.cfsId) {
          if (
            assignedUnitShift.status === AssignedUnitShiftStatus.COMPLETED ||
            assignedUnitShift.status === AssignedUnitShiftStatus.CANCELED
          ) {
            canceledAndOpenUnitShifts.push(assignedUnitShift);
          } else {
            currentUnitShifts.push(assignedUnitShift);
          }
        } else {
          if (
            assignedUnitShift.status !== AssignedUnitShiftStatus.COMPLETED &&
            assignedUnitShift.status !== AssignedUnitShiftStatus.CANCELED &&
            (!agencyId || assignedUnitShift.unitShift.unit.agency === agencyId)
          ) {
            otherUnitShifts.push(assignedUnitShift);
          }
        }
      });

      if (cfs?.isClosed && cfs?.assignedUnitShifts) {
        currentUnitShifts.length = 0;
        canceledAndOpenUnitShifts.length = 0;

        cfs.assignedUnitShifts.forEach((assignedUnitShift) => {
          if (
            assignedUnitShift.status === AssignedUnitShiftStatus.COMPLETED ||
            assignedUnitShift.status === AssignedUnitShiftStatus.CANCELED
          ) {
            canceledAndOpenUnitShifts.push(assignedUnitShift);
          } else {
            currentUnitShifts.push(assignedUnitShift);
          }
        });
      }

      return {
        currentCallUnitShifts: currentUnitShifts,
        otherCallUnitShifts: otherUnitShifts,
        canceledAndOpenShifts: canceledAndOpenUnitShifts,
      };
    }, [assignedUnitShifts, searchText, cfs, cfsId, agencyId]);

  const currentCallUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: currentCallUnitShifts,
    searchText,
  }) as AssignedUnitShiftItem[];

  const otherCallUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: otherCallUnitShifts,
    searchText,
  }) as AssignedUnitShiftItem[];

  const canceledAndOpenUnitShiftsData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: canceledAndOpenShifts,
    searchText,
  }) as AssignedUnitShiftItem[];

  useEffect(() => {
    if (currentCallUnitShiftData.length) {
      setExpanded('current-calls');
    } else if (otherCallUnitShifts.length) {
      setExpanded('other-calls');
    }
  }, []);

  const handleExpand = (panel: string) => {
    if (expanded === panel) setExpanded(null);
    else setExpanded(panel);
  };

  const handleSelectStatus = async (
    action: string | number,
    selectedCfsId = '',
    assignedUnitShiftId = '',
    unitShiftId?: string,
    reassign?: boolean,
  ) => {
    showLoader();
    try {
      let successMsg = '';

      if (action === 'assignToHQ' || action === 'assignToCall') {
        await CfsApi.updateAssignedUnitShiftDispatchedToHQStatus(
          selectedCfsId,
          assignedUnitShiftId,
        );
      } else {
        if (cfsId && action === AssignedUnitShiftStatus.QUEUED) {
          await CfsApi.addUnitShiftToQueue(cfsId, String(unitShiftId));
        } else {
          await CfsApi.updateAssignedUnitShiftStatus(
            selectedCfsId,
            assignedUnitShiftId,
            { status: action as AssignedUnitShiftStatus },
          );
          if (action === 'COMPLETED' || action === 'CANCELED') {
            const assignedUS = assignedUnitShifts.find(
              (unit) => unit._id === assignedUnitShiftId,
            );
            assignedUS?.unitShift?.users?.forEach((user) => {
              channel?.publish(`closed:cfs:${cfsId}:user:${user._id}`, {
                id: cfsId,
                event: 'assignmentEnded',
              });
            });
          }
        }
        await fetchAssignedUnitShifts();
      }

      switch (action) {
        case 'assignToHQ':
          successMsg = 'Unit shift is assigned to HQ';
          break;
        case 'assignToCall':
          successMsg = 'Unit shift is assigned to a call';
          break;
        case AssignedUnitShiftStatus.EN_ROUTE:
          successMsg = 'Unit shift is now en route';
          break;
        case AssignedUnitShiftStatus.ON_SCENE:
          successMsg = 'Unit shift has arrived on scene';
          break;
        case AssignedUnitShiftStatus.PROGRESS:
          successMsg = 'Unit shift assignment is now in progress';
          break;
        case AssignedUnitShiftStatus.COMPLETED:
          successMsg = 'Unit shift assignment completed';
          break;
        case AssignedUnitShiftStatus.ASSIGNED:
          successMsg = 'Unit shift assigned';
          break;
        case AssignedUnitShiftStatus.CANCELED:
          successMsg = 'Unit shift assignment canceled';
          break;
        case AssignedUnitShiftStatus.QUEUED:
          successMsg = 'Unit shift assignment queued';
          break;
      }

      if (
        reassign &&
        cfsId &&
        unitShiftId &&
        action !== AssignedUnitShiftStatus.QUEUED
      ) {
        await CfsApi.assignUnitShift(cfsId, unitShiftId);
        successMsg = 'Unit shift successfully reassigned to the current call';
      }
      updateToast({
        type: ConfirmTypes.SUCCESS,
        open: true,
        message: successMsg,
      });
      handleAssignUnit?.();
      if (cfsId) fetchCFS();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  if (!cfsId) {
    return (
      <Stack flex={1} sx={{ padding: '15px', overflow: 'auto' }}>
        {otherCallUnitShiftData.map((item, index) => (
          <AssignedUnitItem
            {...item}
            key={index}
            isCfsClosed={cfs?.isClosed}
            unitShiftActionItems={unitShiftActionItems}
            handleSelectAction={(value) =>
              handleSelectStatus(value, item.cfsId, item._id)
            }
          />
        ))}
      </Stack>
    );
  }

  return (
    <Stack flex={1}>
      {(!!currentCallUnitShiftData.length ||
        !!canceledAndOpenShifts.length) && (
        <UnitAccordion
          title="Current call"
          value="current-calls"
          badge={currentCallUnitShiftData.length}
          currentValue={expanded}
          handleExpand={handleExpand}
        >
          {currentCallUnitShiftData.map((assignedUnitShift) => (
            <AssignedUnitItem
              {...assignedUnitShift}
              key={assignedUnitShift._id}
              isCfsClosed={cfs?.isClosed}
              unitShiftActionItems={
                assignedUnitShift.isDispatchedToHQ
                  ? unitShiftHQActionItems
                  : assignedUnitShift.unitShift.unit.type.isDispatchToHQEnabled
                  ? unitShiftCallActionItems
                  : unitShiftActionItems
              }
              handleSelectAction={(value) =>
                handleSelectStatus(
                  value,
                  assignedUnitShift.cfsId,
                  assignedUnitShift._id,
                )
              }
            />
          ))}
          {canceledAndOpenUnitShiftsData.map((assignedUnit, index) => {
            const status = assignedUnit.status;
            return (
              <UnitItem
                key={index}
                {...assignedUnit.unitShift.unit}
                addressLabel={getUnitShiftAddressLabel(
                  assignedUnit.unitShift,
                  unitShiftAddresses,
                )}
                distance={
                  unitShiftDistances[assignedUnit.unitShift?._id as string]
                }
                resources={{
                  polygons: assignedUnit.unitShift.polygons,
                }}
                rootProps={{
                  sx: { backgroundColor: colors.grey[20] },
                }}
                hideDetail
              >
                <Box className="name-box status-box">
                  {status.toLowerCase()}
                </Box>
              </UnitItem>
            );
          })}
        </UnitAccordion>
      )}
      {!!otherCallUnitShiftData.length && (
        <UnitAccordion
          title="Other calls"
          value="other-calls"
          badge={otherCallUnitShiftData.length}
          currentValue={expanded}
          handleExpand={handleExpand}
        >
          {otherCallUnitShiftData.map((assignedUnitShift) => (
            <AssignedUnitItem
              {...assignedUnitShift}
              isCfsClosed={cfs?.isClosed}
              key={assignedUnitShift._id}
              unitShiftActionItems={[]}
              handleSelectAction={(value, reassign) =>
                handleSelectStatus(
                  value,
                  assignedUnitShift.cfsId,
                  assignedUnitShift._id,
                  assignedUnitShift.unitShift._id,
                  reassign,
                )
              }
              reassign
            />
          ))}
        </UnitAccordion>
      )}
    </Stack>
  );
};

export default withLoader(AssignedUnits);
