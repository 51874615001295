import { useEffect, useRef } from 'react';

import { useMap } from '@vis.gl/react-google-maps';

import { MapBreadcrumbItem } from '@/components/GoogleMap/MapBreadcrumb';

interface MapPolyLineProps {
  path: MapBreadcrumbItem[];
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
}

export const MapPolyLine = ({
  path,
  strokeColor,
  strokeOpacity,
  strokeWeight,
}: MapPolyLineProps) => {
  const map = useMap();

  const polylineRef = useRef<google.maps.Polyline | null>(null);

  useEffect(() => {
    if (map) {
      polylineRef.current = new google.maps.Polyline({
        path,
        strokeColor,
        strokeOpacity,
        strokeWeight,
        map,
      });
    }

    // Clean up the polyline
    return () => {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }
    };
  }, [map, path, strokeColor, strokeOpacity, strokeWeight]);

  return null;
};
