import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MasterNameApi } from '@/api';
import { DisabledOverlay } from '@/components/DisabledOverlay';
import { useKaseContext } from '@/hooks';
import { useTabLayoutContext } from '@/layouts/TabLayout';
import { IMasterNamePerson } from '@/models';
import { useToastStore } from '@/store';
import { parsePhoneNumber } from '@/utils';

import { useMasterNamesContext } from '..';
import { MasterAddDriverLicense } from './MasterAddDriverLicense';
import { MasterLookupName } from './MasterLookupName';
import { MasterNameDriverLicense } from './MasterNameDriverLicense';
import { MasterNameDriverLicenseView } from './MasterNameDriverLicenseView';
import MasterNameForm from './MasterNameForm';
import { TabNamesScreens } from '../data';

type MasterAddNameDetailsProps = {
  isMasterIndex?: boolean;
  hideHeader?: boolean;
  onGoBack?: () => void;
};

export const MasterAddNameDetails = ({
  isMasterIndex,
  hideHeader,
  onGoBack,
}: MasterAddNameDetailsProps) => {
  const { updateToast } = useToastStore();
  const { currentMastername, setCurrentMastername } = useMasterNamesContext();
  const { currentScreen } = useTabLayoutContext();
  const { kase } = useKaseContext();

  const { id: personId } = useParams();

  const methods = useForm<IMasterNamePerson & { ssn_prev: string }>({
    defaultValues: {
      firstName: '',
      lastName: '',
      isVerified: false,
      physicalCharacteristics: {
        others: [],
      },
    },
    mode: 'all',
  });
  
  useEffect(() => {
    if (currentMastername?.masterName._id) fetchCurrentMastername();
  }, [currentMastername]);

  useEffect(() => {
    if (personId) fetchCurrentMastername();
  }, [personId]);

  const fetchCurrentMastername = async () => {
    try {
      let res;
      if (personId) {
        res = await MasterNameApi.getMasterName(personId);
      } else {
        res = await MasterNameApi.getMasterName(
          String(currentMastername?.masterName._id),
        );
      }
      const phoneNumbers = res.data.phoneNumbers?.map(
        ({ type, phoneNumber }) => ({
          type,
          phoneNumber: parsePhoneNumber(phoneNumber),
        }),
      );
      const { employerInfo, ssn } = res.data;
      methods.reset({
        ...res.data,
        employerInfo: {
          ...employerInfo,
          phoneNumber: parsePhoneNumber(employerInfo?.phoneNumber),
        },
        ssn_prev: ssn,
        phoneNumbers,
      });

      setCurrentMastername({
        masterName: res.data,
        relationship: {},
        kaseId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  switch (currentScreen) {
    case TabNamesScreens.MasterLookupName:
      return <MasterLookupName />;
    case TabNamesScreens.MasterNameDriverLicense:
      return <MasterNameDriverLicense />;
    case TabNamesScreens.MasterAddDriverLicense:
      return <MasterAddDriverLicense />;
    case TabNamesScreens.MasterNameDriverLicenseView:
      return <MasterNameDriverLicenseView />;
    default:
      return (
        <FormProvider {...methods}>
          <MasterNameForm
            hideHeader={hideHeader}
            onGoBack={onGoBack}
            isMasterIndex={isMasterIndex}
          />
          {kase && <DisabledOverlay />}
        </FormProvider>
      );
  }
};
