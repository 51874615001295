import { Stack, styled } from '@mui/material';

const DisabledOverlayStack = styled(Stack)(() => ({
  position: 'fixed',
  backgroundColor: 'black',
  opacity: 0.5,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  cursor: 'not-allowed',
}));

export const DisabledOverlay = () => {
  return <DisabledOverlayStack></DisabledOverlayStack>;
};
