import { phoneNumberInput } from '@/constants/form_inputs';
import { IReporter } from '@/models';

export const ReporterFormDefaultValues: IReporter = {
  isCallingForSelf: false,
  isKnowSubject: false,
  notOnLocation: false,
  notWantToBeSeen: false,
};

export const ReporterFormFields = [
  {
    ...phoneNumberInput,
    name: 'reporter.contactPhoneNumber',
    label: 'Contact phone number',
    styles: {
      xs: 12,
      sx: {
        mt: 2,
      },
    },
  },
  {
    name: 'reporter.isCallingForSelf',
    type: 'checkbox',
    label: 'Calling for themselves',
    styles: {
      xl: 6,
      sx: {
        mt: 2,
        mb: 0.25,
      },
    },
  },
  {
    name: 'reporter.isKnowSubject',
    type: 'checkbox',
    label: 'Do they know the subject?',
    styles: {
      xl: 6,
      sx: {
        mt: 2,
        mb: 0.25,
      },
    },
  },
  {
    name: 'reporter.notOnLocation',
    type: 'checkbox',
    label: 'Not on location',
    styles: {
      xl: 6,
      sx: {
        mt: 0.25,
        mb: 0.25,
      },
    },
  },
  {
    name: 'reporter.notWantToBeSeen',
    type: 'checkbox',
    label: "Doesn't want to be seen",
    styles: {
      xl: 6,
    },
  },
];

export const MasterNameFormFields = [
  {
    name: 'firstName',
    label: 'First name',
    rules: {
      required: 'This field is required.',
      maxLength: {
        value: 50,
        message: 'Name should be less than 50 characters.',
      },
    },
    grid: {
      md: 6,
    },
  },
  {
    name: 'lastName',
    label: 'Last name',
    rules: {
      required: 'This field is required.',
      maxLength: {
        value: 50,
        message: 'Name should be less than 50 characters.',
      },
    },
    grid: {
      md: 6,
    },
  },
  {
    name: 'address',
    type: 'address',
    label: 'Address',
    grid: {
      md: 12,
    },
  },
];

export const MasterNameDefaultValues = {
  firstName: '',
  lastName: '',
};
