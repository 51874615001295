import { useEffect, useState } from 'react';

import { CfsApi } from '@/api';
import { ICallerLocation, ICFS } from '@/models';

export const useRapidSos = (cfs: ICFS | null) => {
  const [locations, setLocations] = useState<ICallerLocation[]>([]);

  const fetchCallerLocations = async () => {
    if (!cfs?._id) {
      return;
    }

    const apiResult = await CfsApi.getCfsCallerLocations(cfs._id);
    if (
      apiResult.status === 200 &&
      apiResult.data &&
      apiResult.data.length > 0
    ) {
      const newLocations = apiResult.data;
      if (locations.length === 0) {
        // The first fetch, nothing to check.
        setLocations(newLocations);
      } else if (locations[locations.length - 1].created_time) {
        // Check if the latest location is different from the current active one, if so add an entry to the locations list.
        const lastNewLocation = newLocations[newLocations.length - 1];
        const lastLocation = locations[locations.length - 1];

        if (lastLocation.created_time !== lastNewLocation.created_time) {
          setLocations((v) => [...v, ...apiResult.data]);
        }
      }
    }
  };

  useEffect(() => {
    if (cfs?.reporter?.callerPhoneNumber && !cfs.isClosed) {
      const t = setInterval(() => fetchCallerLocations(), 5000);
      return () => clearInterval(t);
    }
  }, [cfs?.reporter?.callerPhoneNumber, cfs?.isClosed]);

  if (cfs === null) {
    return {};
  }

  return {
    locations,
  };
};
