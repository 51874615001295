import { AxiosResponse } from 'axios';

import { http } from '@/api/http';
import { ApiVersion } from '@/config';

export interface FileToken {
  sasToken: string;
  expiresOn: string;
  folder: string;
}

export const StorageApi = {
  getSasTokens: (): Promise<AxiosResponse<FileToken[]>> => {
    return http.post(`api/${ApiVersion.V1}/storage/folders-sas-tokens`);
  },
};
