import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';

import { IcBellCrossed } from '@/assets/images';
import { colors } from '@/theme/variables';
import { ModalState } from '../shared/modalState';

type BackupRequestRejectedModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  unitName: string;
  onClose?: () => void;
};

const BackupRequestRejectedModalContent: React.FC<BackupRequestRejectedModalContentProps> = ({
  toggleModal,
  unitName,
  onClose
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    toggleModal('backupRequestRejected');
  };

  return (
    <>
    
      <Stack rowGap={2} flex={1} alignItems="center" fontSize={40} padding={5}>
        <IcBellCrossed width={84} height={84} color={colors.error.main} />
        <Typography 
          component="h3" 
          variant="h6" 
          className="text-center"
        >
          Backup Request Rejected
        </Typography>
        <Typography 
          className="text-center text-gray-600"
        >
          Unit {unitName} has declined your backup request.
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" justifyContent="center" paddingTop={2}>
          <Button
            color="error"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={handleClose}
          >
            OK
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default BackupRequestRejectedModalContent;