export type ModalState = {
  noCfsAssigned: boolean;
  newCfsAssigned: boolean;
  directions: boolean;
  requestBackup: boolean;
  backupRequested: boolean;
  backupRequestRejected: boolean;
  cfsDetails: boolean;
};

export const initialModalState: ModalState = {
  cfsDetails: false,
  requestBackup: false,
  backupRequested: false,
  backupRequestRejected: false,
  directions: false,
  newCfsAssigned: false,
  noCfsAssigned: false,
};
