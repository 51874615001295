import { MouseEvent, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconButton, MenuItem, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { ISideBarItem } from '@/layouts/data';

import { SideBarMenu, TooltipTitle } from './styles';

export const SideBarItem = (props: ISideBarItem) => {
  const { label, items, link, submenuEl, setSubmenuEl = () => {} } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeLink = useMemo(() => {
    let activedLink: string | null = null;
    const paths = pathname.split('/');
    if (paths[1] === link.split('/')[1]) activedLink = link;
    else {
      items?.map((item) => {
        if (paths[1] === item.link.split('/')[1])
          return (activedLink = item.link);
      });
    }
    return activedLink;
  }, [pathname]);

  const handleNavItemAction = (e: MouseEvent<HTMLButtonElement>) => {
    if (!items) return navigate(link);
    if (submenuEl) setSubmenuEl(undefined);
    else setSubmenuEl(e.currentTarget);
  };

  const onClickMenuItem = (itemLink?: string) => {
    setSubmenuEl(undefined);
    if (itemLink) navigate(itemLink);
  };

  const menuItems = () => {
    return items?.map((item) => (
      <MenuItem
        key={item.key}
        className={clsx(activeLink === item.link && 'active')}
        onClick={() => onClickMenuItem(item.link)}
      >
        {item.label}
      </MenuItem>
    ));
  };

  if (!items) {
    return (
      <Tooltip
        title={<TooltipTitle className="title">{label}</TooltipTitle>}
        placement="right-start"
        components={{ Tooltip: 'div' }}
      >
        <IconButton
          className={clsx('list-item', !!activeLink && 'active')}
          onClick={handleNavItemAction}
          onMouseEnter={() => {
            setSubmenuEl(undefined);
          }}
        >
          {props.icon ? <props.icon /> : ''}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div>
      <IconButton
        className={clsx('list-item', !!activeLink && 'active')}
        onMouseEnter={handleNavItemAction}
      >
        {props.icon ? <props.icon /> : ''}
      </IconButton>

      {!!items && (
        <SideBarMenu
          id="sidebar-nav-item-menu"
          PopoverClasses={{
            root: 'submenuRoot',
            paper: 'submenuPaper',
          }}
          anchorEl={submenuEl}
          open={!!submenuEl}
          onClose={() => onClickMenuItem()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div
            onMouseLeave={() => {
              setSubmenuEl(undefined);
            }}
          >
            {menuItems()}
          </div>
        </SideBarMenu>
      )}
    </div>
  );
};
