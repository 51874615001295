import { useState } from 'react';

import { IconButton } from '@mui/material';
import { InfoWindow } from '@vis.gl/react-google-maps';

import { IcClose } from '@/assets/images';
import { MapPolyLine } from '@/components/GoogleMap/MapPolyLine';
import { Circle } from '@/pages/Tools/Cameras/components/Circle';

export interface MapBreadcrumbItem {
  lat: number;
  lng: number;
  info?: React.ReactElement;
}

interface MapBreadcrumbProps {
  path: MapBreadcrumbItem[];
  color?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  circleRadius?: number;
}

interface InfoWindowState {
  showInfoWindow: boolean;
  content?: React.ReactElement | null;
  position: {
    lat: number;
    lng: number;
  };
}

const defaultInfoState: InfoWindowState = {
  showInfoWindow: false,
  content: null,
  position: {
    lat: 0,
    lng: 0,
  },
};

export const MapBreadcrumb = ({
  path,
  color,
  strokeWeight,
  circleRadius,
}: MapBreadcrumbProps) => {
  const [infoWindow, setInfoWindow] =
    useState<InfoWindowState>(defaultInfoState);

  const clearInfoWindow = () => {
    setInfoWindow(defaultInfoState);
  };

  const handleCircleClick = (
    e: google.maps.MapMouseEvent,
    item: MapBreadcrumbItem,
  ) => {
    if (item.info) {
      setInfoWindow({
        showInfoWindow: true,
        content: item.info,
        position: {
          lat: e.latLng?.lat() ?? 0,
          lng: e.latLng?.lng() ?? 0,
        },
      });
    }
  };

  return (
    <>
      <MapPolyLine
        path={path}
        strokeColor={color}
        strokeOpacity={1}
        strokeWeight={strokeWeight}
      />

      {path.map((item, key) => (
        <Circle
          key={key}
          zIndex={2}
          strokeOpacity={1}
          fillOpacity={1}
          strokeWeight={strokeWeight}
          center={item}
          radius={circleRadius}
          strokeColor="white"
          fillColor={color}
          onClick={(e) => handleCircleClick(e, item)}
        />
      ))}

      {infoWindow.showInfoWindow && (
        <InfoWindow
          zIndex={2}
          position={{
            lat: infoWindow.position.lat,
            lng: infoWindow.position.lng,
          }}
        >
          <IconButton
            onClick={clearInfoWindow}
            sx={{ position: 'absolute', right: '2px', top: '2px', padding: 0 }}
          >
            <IcClose style={{ width: 15, height: 15 }} />
          </IconButton>
          {infoWindow.content}
        </InfoWindow>
      )}
    </>
  );
};
