import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { stage } from '@/config';
import { IAccount, IUser } from '@/models';

import packageJSON from '../../package.json';

export const initSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: stage,
    release: `${packageJSON.name}@${packageJSON.version}`,
    autoSessionTracking: true,
    enabled: !['local', 'test'].includes(stage),
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay({
        maskAllText: stage === 'prod',
        blockAllMedia: true,
      }),
    ],
    beforeSend(event) {
      const exceptionValues = event.exception?.values;
      const hasAuthenticationError = exceptionValues?.find(
        ({ type, value }) =>
          type === 'AxiosError' &&
          value === 'Request failed with status code 401',
      );
      if (hasAuthenticationError) return null;
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^\/api\//],

    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate, the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.2 and profilesSampleRate of 0.5 would results in 10% of transactions being profiled (0.2 * 0.5 = 0.1)
    profilesSampleRate: 0.5,
  });
};

export const setSentryScopes = (user: IUser, account: IAccount) => {
  Sentry.setUser({
    id: user._id,
    email: user.email,
    username: user.fullName,
  });
  Sentry.setTag('account', account.subdomain);
  Sentry.setContext('account', {
    id: account._id,
    name: account.name,
    subdomain: account.subdomain,
  });
};
