import { FC, ReactElement } from 'react';

import {
  Card,
  Divider,
  Modal,
  ModalProps,
  styled,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

const closeReasons = ['backdropClick', 'escapeKeyDown'] as const;
type CloseReason = (typeof closeReasons)[number];

export interface BaseModalProps extends Omit<ModalProps, 'children'> {
  open: boolean;
  hideCloseButton?: boolean;
  title?: string;
  classes?: {
    root?: string;
    content?: string;
    title?: string;
    divider?: string;
  };
  children?: ReactElement;
  onClose: () => void;
}

const Root = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'auto',

  '& .modal-content': {
    minWidth: 600,
    padding: 20,
    borderRadius: 24,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1400,
    },
    '& .header': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .btn-close': {
      color: theme.palette.error.main,
    },
    '& .divider': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& .no-divider': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .title': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  '& .fixed-width': {
    maxWidth: '600px',
  },
}));

export interface FirstResponderModalProps extends Omit<ModalProps, 'children'> {
  open: boolean;
  hideCloseButton?: boolean;
  title?: string;
  classes?: {
    root?: string;
    content?: string;
    title?: string;
    divider?: string;
  };
  icon?: ReactElement;
  children?: ReactElement;
  onClose: () => void;
  onBackdropClick?: () => void;
}

export const FirstResponderModal: FC<FirstResponderModalProps> = ({
  title,
  icon,
  classes,
  onClose,
  onBackdropClick,
  children,
  ...rest
}) => {
  const handleClose = (
    _event: React.MouseEvent<HTMLElement>,
    reason: CloseReason,
  ) => {
    if (closeReasons.includes(reason) && onBackdropClick) {
      onBackdropClick();
    } else {
      onClose();
    }
  };

  return (
    <Root onClose={handleClose} className={classes?.root} {...rest}>
      <Card className={clsx('modal-content', classes?.content)}>
        <Typography
          variant="h6"
          fontWeight={600}
          component="h2"
          className={clsx('header', classes?.content)}
        >
          {title}
        </Typography>

        {title && <Divider className={clsx('divider', classes?.divider)} />}
        {children}
      </Card>
    </Root>
  );
};
