import { IAuditModel, IPoint, ObjectId } from '.';

export enum AddressStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PointType {
  ROOF_TOP = 1,
  PRIMARY_STRUCTURE_ENTRANCE,
  DRIVEWAY_ENTRANCE,
  PARCEL_CENTROID,
  MISCELLANEOUS,
}

export enum PlaceType {
  BENCH = 'Bench',
  BOAT_SITE = 'Boat Site',
  BRIDGE = 'Bridge',
  CAMPSITE = 'Campsite',
  CEMETERY = 'Cemetery',
  CULVERT = 'Culvert',
  DAM = 'Dam',
  ESN_LINE = 'ESN Line',
  FUEL_SOURCE = 'Fuel Source',
  GATE = 'Gate',
  LANDING_ZONE = 'Landing Zone',
  INTERSECTION = 'INTERSECTION',
  KIOSK = 'Kiosk',
  MILEMARKER = 'Milemarker',
  MONUMENT = 'Monument',
  MUNICIPAL_BOUNDARY_LINE = 'Municipal Boundary Line',
  PARKING_LOT = 'Parking Lot',
  PHONE = 'Phone',
  PUBLIC_RECREATION_AREA = 'Public Recreation Area',
  ROAD_END = 'Road End',
  SUBTERRANEAN = 'Subterranean',
  TOWER = 'Tower',
  TRAILHEAD = 'Trailhead',
  UTILITY = 'Utility',
  WATER_BODY = 'Water Body',
  WATER_SOURCE = 'Water Source',
  WIND_TURBINE = 'Wind Turbine',
  VANITY_ADDRESS = 'Vanity Address',
}

export enum MunicipalityType {
  CITY = 1,
  TOWN,
  VILLAGE,
}

export interface IAddressForm extends ObjectId {
  intersectionStreets?: string[] | null;
  stateAddressPointId?: string;
  stateStreetId?: string;
  addressNumber?: string;
  suffixAddressNumber?: string;
  subAddress?: string;
  structure?: string;
  floor?: string;
  unit?: string;
  location?: string;
  businessName?: string;
  state?: string;
  zipcode?: string;
  zipName?: string;
  pointType?: PointType;
  placeName?: string;
  placeType?: PlaceType;
  completeStreetName?: string;
  addressLabel?: string;
  country?: string;
  milePost?: string;
  municipalityType?: MunicipalityType;
  point?: IPoint;
  normalized?: string;
}

export interface IAddress extends IAddressForm, IAuditModel {
  status: AddressStatus;
  crossStreet1?: string;
  crossStreet2?: string;
  distance1?: number;
  distance2?: number;
  nearestIntersection?: string[];
}
