import { useMemo } from 'react';

import { Chip } from '@/components';
import { ASSIGNMENT_STATUS } from '@/constants';
import { AssignmentStatus } from '@/models';
import { colors } from '@/theme/variables';

interface CFSAssignmentStatusItemProps {
  isReadyToDispatch?: boolean;
  assignmentStatus?: AssignmentStatus;
  className?: string;
}

export const CFSAssignmentStatusItem = (
  props: CFSAssignmentStatusItemProps,
) => {
  const { isReadyToDispatch, assignmentStatus, className } = props;

  const itemValues = useMemo(() => {
    if (isReadyToDispatch && assignmentStatus === AssignmentStatus.NEW) {
      return { label: 'Ready for Dispatch', color: colors.warning.main };
    }
    return assignmentStatus ? ASSIGNMENT_STATUS[assignmentStatus] : null;
  }, [isReadyToDispatch, assignmentStatus]);

  return (
    <Chip
      label={itemValues?.label}
      className={className}
      sx={{
        backgroundColor: itemValues?.color,
      }}
    />
  );
};
