import { Stack, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const FormTextEditorFieldRoot = styled(Stack)(() => ({
  flex: 1,

  '& .rdw-editor-wrapper': {
    border: `1px solid ${colors.grey[10]}`,
    borderRadius: 2,
  },
  '& .rdw-editor-toolbar': {
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  '& .rdw-editor-main': {
    overflow: 'auto',
  },
  '& .rdw-suggestion-dropdown': {
    width: '400px',
    maxHeight: 'fit-content',
  },
}));
