import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';

import { MasterIndexApi, CautionApi, UserApi } from '@/api';
import { withLoader, WithLoaderProps } from '@/hocs';
import { IMasterCaution, ICautionType, IUser } from '@/models';
import { useToastStore } from '@/store';

interface UserMap {
  [key: string]: IUser;
}

const MasterAddressCautionsView = ({
  showLoader,
  hideLoader,
}: WithLoaderProps) => {
  const { updateToast } = useToastStore();
  const [cautions, setCautions] = useState<IMasterCaution[]>([]);
  const [cautionTypes, setCautionTypes] = useState<ICautionType[]>([]);
  const [addressDetails, setAddressDetails] = useState<Record<string, string>>(
    {},
  );
  const [userDetails, setUserDetails] = useState<UserMap>({});
  const { addressId } = useParams();

  const fetchCautionTypes = async () => {
    try {
      const response = await CautionApi.getCautionTypes();
      setCautionTypes(response.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const fetchUserDetails = async (userIds: string[]) => {
    const details: UserMap = {};
    try {
      const uniqueIds = [...new Set(userIds)].filter(Boolean);

      await Promise.all(
        uniqueIds.map(async (id) => {
          const response = await UserApi.getOne(id);
          details[id] = response.data;
        }),
      );

      setUserDetails(details);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const fetchAddressDetails = async (addressIds: string[]) => {
    const details: Record<string, string> = {};

    try {
      const uniqueIds = [...new Set(addressIds)].filter(Boolean);

      await Promise.all(
        uniqueIds.map(async (id) => {
          const response = await MasterIndexApi.getMasterAddress(id);
          details[id] = response.data.addressLabel;
        }),
      );

      setAddressDetails(details);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const fetchCautions = async () => {
    if (!addressId) return;
    showLoader();
    try {
      const response = await MasterIndexApi.getMasterAddress(addressId);
      if (response.data.cautions) {
        setCautions(response.data.cautions);

        const addressIds = response.data.cautions
          .map((caution) => caution.address)
          .filter(Boolean);
        const validAddressIds = addressIds.filter((id): id is string =>
          Boolean(id),
        );
        if (validAddressIds.length) {
          await fetchAddressDetails(validAddressIds);
        }

        const userIds = response.data.cautions
          .map((caution) => caution.createdBy)
          .filter(Boolean);
        const validUserIds = userIds.filter((id): id is string => Boolean(id));
        if (validUserIds.length) {
          await fetchUserDetails(validUserIds);
        }
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
    }
  };

  // This is a recursive function to handle the case where the caution type name is an ObjectId
  const getTypeLabel = (typeId: string, depth = 0): string => {
    if (depth > 2) return typeId;

    const cautionType = cautionTypes.find((type) => type._id === typeId);
    if (!cautionType) return typeId;

    const isObjectId = /^[0-9a-f]{24}$/.test(cautionType.name);
    if (isObjectId) {
      return getTypeLabel(cautionType.name, depth + 1);
    }
    return cautionType.name;
  };

  useEffect(() => {
    if (addressId) {
      fetchCautionTypes();
      fetchCautions();
    }
  }, [addressId]);

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Date and time</TableCell>
              <TableCell>Added by</TableCell>
              <TableCell>Related to</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cautions.map((caution) => (
              <TableRow key={caution._id}>
                <TableCell>{getTypeLabel(caution.type)}</TableCell>
                <TableCell>{caution.comment}</TableCell>
                <TableCell>
                  {caution.createdAt
                    ? new Date(caution.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    : '-'}
                </TableCell>
                <TableCell>
                  {caution.createdBy
                    ? userDetails[caution.createdBy]?.fullName ||
                      caution.createdBy
                    : '-'}
                </TableCell>
                <TableCell>
                  {caution.address
                    ? addressDetails[caution.address] || caution.address
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default withLoader(MasterAddressCautionsView);
