import { IconButton } from '@mui/material';
import { MapControl, ControlPosition, useMap } from '@vis.gl/react-google-maps';

import { IcZoomIn, IcZoomOut } from '@/assets/images';

export type ZoomControlProps = {
  position?: ControlPosition;
};

export const ZoomControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
}: ZoomControlProps) => {
  const map = useMap();
  if (!map) return null;

  const updateZoom = (isZoomIn?: boolean) => {
    const currentZoom = map.getZoom() || 1;
    const newZoomLevel = Math.min(
      22,
      Math.max(0, isZoomIn ? currentZoom + 1 : currentZoom - 1),
    );
    map.setZoom(newZoomLevel);
  };

  return (
    <MapControl position={position}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton
          onClick={() => updateZoom(true)}
          sx={{ cursor: 'pointer', py: 0 }}
        >
          <IcZoomIn />
        </IconButton>
        <IconButton
          onClick={() => updateZoom()}
          sx={{ cursor: 'pointer', py: 0, mt: -1 }}
        >
          <IcZoomOut />
        </IconButton>
      </div>
    </MapControl>
  );
};
