import { IconButton, Stack, Typography } from '@mui/material';

import { IcDeleteTrash, IcPencil } from '@/assets/images';
import { IUnitFilter } from '@/models';

interface UnitFilterListProps {
  unitFilters: IUnitFilter[];
  onDelete: (id: string) => void;
  onEdit: (selectedItem: IUnitFilter) => void;
}

export const UnitFilterList = (props: UnitFilterListProps) => {
  const { unitFilters, onDelete, onEdit } = props;

  return (
    <Stack spacing={1.5}>
      {unitFilters.map((unitFilter) => (
        <Stack key={unitFilter._id} className="filter-item">
          <Typography className="txt-filter-name">{unitFilter.name}</Typography>
          <Stack flexDirection="row">
            <IconButton sx={{ p: 0, mx: 2 }} onClick={() => onEdit(unitFilter)}>
              <IcPencil />
            </IconButton>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => onDelete(String(unitFilter._id))}
            >
              <IcDeleteTrash />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
